<template>
    <div
        v-if="showAlert"
        class="banner"
        :class="`banner-activate`"
        data-testid="account-activation-banner-container"
    >
        <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
            <div>
                <h5 class="banner-heading fw-bold mb-1">
                    {{ title }}
                </h5>
                <p>
                    {{ description }}
                </p>
            </div>
            <div
                class="w-100 d-grid ml-1"
                v-if="cta"
            >
                <a
                    class="btn btn-activate"
                    @click.prevent="onClickCta"
                    @keydown.prevent="onClickCta"
                    data-testid="account-activation-banner-primary-button"
                >
                    {{ cta }}
                </a>
                <a
                    v-if="secondaryCta"
                    class="btn btn-tertiary border-0"
                    @click.prevent="onSecondaryClickCta"
                    @keydown.prevent="onSecondaryClickCta"
                    data-testid="account-activation-banner-secondary-button"
                >
                    {{ secondaryCta }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import { AvenAccountStanding, AvenCardActivationStatus } from '@/store/overviewStore.types'
    import { useOverviewStore } from '@/store/overviewStore'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import format, { toFormattedUSDStripTrailingZeroCents } from '@/mixins/format'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { startNewCashOutWithCleanSlate } from '@/utils/cashOutUtils'
    import { navigationTabRouteNames } from '@/utils/navigationTabs'

    export default {
        name: 'AccountActivationBanner',
        mixins: [format],
        computed: {
            isWebView() {
                return useDeviceInfoStore().isWebView
            },
            isSingleWebView() {
                return useDeviceInfoStore().isSingleWebView
            },
            cardRequiresActivation() {
                return useOverviewStore().cardRequiresActivation
            },
            cardActivationStatus() {
                return useOverviewStore().cardActivationStatus
            },
            activationRequirementStatus() {
                return useOverviewStore().activationRequirementStatus
            },
            cashOutUpfrontTransferAmountWithoutFee() {
                return useOverviewStore().firstDrawAmountExcludingFee
            },
            shouldStartUpfrontCashOutFlow() {
                return useOverviewStore().shouldStartUpfrontCashOutFlow
            },
            shouldShowUpfrontCashOutInProgress() {
                return useOverviewStore().shouldShowUpfrontCashOutInProgress
            },
            avenAccountStanding() {
                return useOverviewStore().avenAccountStanding
            },
            fastFundingLineSizeIfApproved() {
                return useOverviewStore().fastFunding.lineSizeIfApproved
            },
            isFastFundingEligible() {
                return useOverviewStore().fastFunding.isEligible
            },
            showAlert: function () {
                // account status banner take precedence over activation banner
                if (this.avenAccountStanding !== AvenAccountStanding.current) {
                    return false
                }

                const showOnRoute = navigationTabRouteNames.includes(this.$route.name)
                // my.aven.com has already fetched card activation status by the time Dashboard component loads after login completes.
                // mobile app login is part of native code. accountOverview api is not executed until dashboard.vue loads.
                // isCardActivated remains in unknown state until accountOverview completes.
                // we do not want activation banner to appear while isCardActivated is in unknown state.
                const cardActivationStatusInitialized = useOverviewStore().cardActivationStatus !== AvenCardActivationStatus.unknown
                const result = cardActivationStatusInitialized && showOnRoute && this.cardRequiresActivation

                this.$emit('show', 'AccountActivationBanner', result ? 'show' : 'hide')
                return result
            },
            title: function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.title`)
                }

                if (this.shouldShowUpfrontCashOutInProgress) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontInProgress.title`)
                }

                // If they aren't fast funding eligible, show regular activation language
                if (!this.isFastFundingEligible) {
                    return i18n.t(`components.accountActivationBanner.activate.title`)
                }

                // If they submitted fast funding but got denied, pretend fast-funding never existed
                return i18n.t(`components.accountActivationBanner.activate.title`)
            },
            description: function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.description`, {
                        cashOutUpfrontAmount: toFormattedUSDStripTrailingZeroCents(this.cashOutUpfrontTransferAmountWithoutFee),
                    })
                }

                if (this.shouldShowUpfrontCashOutInProgress) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontInProgress.description`)
                }

                // If they submitted fast funding but got denied, pretend fast-funding never existed
                // If they got approved for fast funding but the fast funding approved amount is equal to their total credit limit, also show a normal message
                return i18n.t(`components.accountActivationBanner.activate.description`)
            },
            cta: function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.cta`)
                }

                if (this.shouldShowUpfrontCashOutInProgress) {
                    return ''
                }

                return i18n.t('components.accountActivationBanner.activate.cta')
            },
            secondaryCta: function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.secondaryCta`)
                }
                return null
            },
        },
        methods: {
            onClickCta: async function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    // reset to start over a new Cash Out
                    startNewCashOutWithCleanSlate()
                    // set the transaction amount for Cash Out Upfront
                    appSessionStorage.setItem(localStorageKey.cashOutAmount, String(this.cashOutUpfrontTransferAmountWithoutFee))
                    appSessionStorage.setItem(localStorageKey.preActivationCashOutFlow, String(true))
                    this.$logEvent('click_cash_out_upfront_now')
                    if (this.isWebView && !this.isSingleWebView) {
                        return (window.location.href = RoutePaths.CASH_OUT_PAYMENT_OPTIONS)
                    } else {
                        return this.$router.push({
                            name: RouteNames.CASH_OUT_PAYMENT_OPTIONS,
                        })
                    }
                }

                if (this.isWebView && !this.isSingleWebView) {
                    return (window.location.href = RoutePaths.CARD_ACTIVATION)
                } else {
                    return this.$router.push({
                        name: RouteNames.CARD_ACTIVATION,
                    })
                }
            },
            onSecondaryClickCta: async function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    this.$logEvent('click_banner_cash_out_upfront_bt_instead')
                    await this.$router.push({ name: RouteNames.CASH_OUT_UP_FRONT_BT_INSTEAD })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/banner';

    .banner {
        max-width: 640px;
        margin: 0;
    }
</style>
