<template>
    <div>
        <ValidationProvider
            :vid="name"
            :mode="validationMode"
            :rules="validationRules"
            v-slot="{ errors }"
        >
            <div
                :class="[
                    'input',
                    containerClasses,
                    {
                        'form-floating': !disableFormFloating, // by default, always on
                        'input-group has-validation': enableInputGroup
                    }
                ]"
            >
                <input
                    type="text"
                    inputmode="decimal"
                    class="form-control"
                    :id="name"
                    v-mask="mask"
                    :class="[{ 'is-invalid': errors[0], 'rounded-bottom-0': appendBottomText }, inputClasses]"
                    :name="name"
                    :placeholder="placeholder"
                    :data-testid="dataTestid"
                    v-model="currencyValue"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                    :disabled="disabled"
                >
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <span
                    v-if="enableInputGroup"
                    :class="['input-group-text bg-white', inputGroupClasses]"
                >
                    <slot name="inputGroupText" />
                </span>
                <div
                    v-if="appendBottomText"
                    class="append-bottom-text bg-light small p-1 ps-2 rounded-bottom border-bottom border-left border-right"
                >
                    {{ appendBottomText }}
                </div>
                <div
                    class="invalid-feedback"
                    data-testid="form-field-currency-error"
                >
                    {{ errors[0] }}
                </div>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
    /**
     * Use for all text input fields. Has label, validation, placeholder and filters.

     * @prop{String} name (required) To modify the text formatting. Can be 'uppercase|lowercase'.
     * @prop{String} validationRules (optional) A string with the vee-validate rules string. ie 'required|max:7'
     * @prop{String} validationMode (optional) A string with the vee-validate mode string. ie 'eager'
     * @prop{String} label (optional) A floating label as placeholder and above the input.
     * @prop{String} classes (optional) A string of any classes you want to apply to the input field
     * @prop{String} placeholder (optional) The text when the input is blank for screen readers.
     * @prop{String} filter (optional) To modify the text formatting. Can be 'uppercase|lowercase'.
     * @prop{Boolean} focus (optional) To set focus on the input. Defaults to false.
     */
    import createNumberMask from '@/plugins/createNumberMask'
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormFieldCurrency',
        mixins: [formInputMixin],
        props: {
            initialValue: {
                type: String,
                default: undefined,
            },
            placeholder: {
                type: String,
                default: '$0.00',
            },
            decimalsToShow: {
                type: Number,
                default: 0,
            },
            enableInputGroup: {
                type: Boolean,
                default: false,
            },
            inputClasses: {
                type: String,
            },
            inputGroupClasses: {
                type: String,
            },
            disableFormFloating: {
                type: Boolean,
                default: false,
            },
            validationRules: { type: String, default: 'required' },
            appendBottomText: { type: String, default: '' },
            dataTestid: { type: String },
            disabled: { type: Boolean },
            containerClasses: { type: String, default: '' },
            validationMode: { type: String, default: 'aggressive' },
            options: {
                type: Object,
                default: () => ({
                    allowDecimal: true,
                    decimalLimit: 2,
                    prefix: '$',
                    allowNegative: false,
                }),
            },
        },
        data() {
            return {
                currencyMask: createNumberMask({
                    allowDecimal: this.options.allowDecimal,
                    decimalLimit: this.options.decimalLimit,
                    allowNegative: this.options.allowNegative,
                    prefix: this.options.prefix,
                }),
                currencyValue: this.value || '',
                mask: '',
            }
        },
        mounted() {
            if (this.initialValue !== undefined) {
                this.currencyValue = this.initialValue
            }
            if (this.value) {
                this.mask = this.currencyMask(this.value)
            }
        },
        watch: {
            currencyValue: function (newValue, oldValue) {
                // There's a bug where if you provide a function to v-mask, it causes an infinite render loop with vee-validate
                // The fix is to make mask a regular variable, and then update the value ourselves here
                // You can't use a computed property, because that's also a function
                // https://github.com/probil/v-mask/issues/511
                this.mask = this.currencyMask(newValue)

                this.$logger.info(`currencyValue: ${oldValue} -> ${newValue}`)
                const filteredNumber = parseFloat(newValue?.replace(/[$,]/gi, ''))
                let filteredValue
                // If the user erases everything from the form field, they are left w/ '$'. The
                // replace() call above removes that, so now we're calling parseFloat(''), which
                // yields NaN. In that case, we want to return '' instead of 'NaN'.
                if (isNaN(filteredNumber)) {
                    filteredValue = ''
                } else {
                    filteredValue = filteredNumber.toFixed(this.options.decimalLimit)
                }

                this.$emit('input', filteredValue)
            },
            // TODO: prefer not to use a watcher bc it adds an unnecessary addition to the memory stack for _all_ currency fields. for sake a speed, this works for now. see josh if any questions.
            initialValue: function (newVal) {
                this.currencyValue = newVal
            },
        },
    }
</script>
