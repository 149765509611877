import { render, staticRenderFns } from "./TransactionList.vue?vue&type=template&id=b589c464&scoped=true"
import script from "./TransactionList.vue?vue&type=script&lang=js"
export * from "./TransactionList.vue?vue&type=script&lang=js"
import style0 from "./TransactionList.vue?vue&type=style&index=0&id=b589c464&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b589c464",
  null
  
)

export default component.exports