<template>
    <div class="d-grid">
        <button
            class="btn"
            :class="buttonClasses"
            :type="type"
            v-on="$listeners"
            @click="onSubmit"
            :disabled="submitting || disabled"
            :data-testid="dataTestid"
        >
            <!-- in order to use a package img, you need to pass it as
            <form-button :image-src="require('path.svg')"> -->
            <img
                v-show="imageSrc"
                class="me-1"
                width="16"
                :src="imageSrc"
                alt="icon"
            >
            <span
                v-if="submitting"
                class="spinner-border spinner-border-sm"
            />
            <slot v-else-if="!!$slots.default" />
            <span v-else>{{ label }}</span>
        </button>
    </div>
</template>

<script>
    /**
     * This button is typically used inside a form.
     */
    export default {
        name: 'FormButton',
        props: {
            label: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                default: 'submit',
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                default: null,
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary',
            },
            eventName: {
                type: String,
                default: 'click_button_undefined',
            },
            eventProps: {
                type: Object,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        // TODO: figure out a nice way to pass strings
        //       unfortunately `require` doesn't work inside of packages
        //       i've tried using an async/await import but that doesn't work either
        // computed: {
        //     getImageSrc() {
        //         if (!this.imageSrc) {
        //             return null
        //         }
        //
        //         return require(this.imageSrc)
        //     },
        // },
        methods: {
            onSubmit() {
                if (this.$logEvent) {
                    this.$logEvent(this.eventName, this.eventProps)
                }
                let logMessage = `click button - label: ${this.label} - event name: ${this.eventName}`
                if (this.eventProps) {
                    logMessage += ` - event props: ${JSON.stringify(this.eventProps)}`
                }

                this.$logger.info(logMessage)
            },
        },
    }
</script>
