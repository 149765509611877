<template>
    <card-block
        v-if="shouldShowCard"
        class="card-block"
        :title="cardTitle"
        :no-horizontal-padding="noHorizontalPadding"
    >
        <div
            class="d-flex flex-row justify-content-center mb-1"
            v-if="additionalInterestSavingsAmountString"
        >
            <div class="btn-pill additional-balance-pill p-1 text-white">
                {{ additionalInterestSavingsAmountString }}
            </div>
        </div>
        <h3 class="text-center">
            {{ formattedYearlyEstimatedSavings }}
        </h3>
        <p class="text-center">
            {{ $t('components.estimatedSavingsCard.amountLabelTop') }}
        </p>
        <p
            class="small text-center"
            v-html="$t('components.estimatedSavingsCard.amountLabelBottom')"
        />

        <div>
            <div class="savings-graph d-flex flex-row position-relative">
                <div class="small fw-bold graph-label left">
                    {{ formattedYearlyEstimatedSavingsBulletLabel }}
                </div>
                <div class="small fw-bold graph-label right">
                    {{ formatted4YearEstimatedSavingsBulletLabel }}
                </div>
            </div>
            <div class="d-flex flex-row mt-1">
                <div class="d-flex flex-row flex-fill justify-content-between ps-1 pe-2">
                    <p class="small text-muted">
                        {{ $t('components.estimatedSavingsCard.yearLabels.now') }}
                    </p>
                    <p class="small text-muted">
                        {{ $t('components.estimatedSavingsCard.yearLabels.one') }}
                    </p>
                    <p class="small text-muted">
                        {{ $t('components.estimatedSavingsCard.yearLabels.two') }}
                    </p>
                    <p class="small text-muted">
                        {{ $t('components.estimatedSavingsCard.yearLabels.three') }}
                    </p>
                    <p class="small text-muted">
                        {{ $t('components.estimatedSavingsCard.yearLabels.four') }}
                    </p>
                </div>
            </div>
        </div>

        <button
            class="btn btn-outline-secondary mt-2"
            @click="handleInviteFriendsClick"
        >
            {{ $t('components.estimatedSavingsCard.inviteButtonCta') }}
        </button>

        <button
            class="btn btn-link small mt-2 fw-normal"
            @click="handleToggleCalculationClick"
        >
            {{ $t('components.estimatedSavingsCard.calculationToggleCta') }}
        </button>

        <div
            v-if="showCalculation"
            class="mt-2"
        >
            <div class="d-flex flex-row justify-content-between">
                <p>{{ $t('components.estimatedSavingsCard.calculationTable.lastStatementBalanceLabel') }}</p>
                <p>{{ formattedCurrentBalanceDollars }}</p>
            </div>
            <hr class="my-0">
            <div class="d-flex flex-row justify-content-between">
                <p>{{ $t('components.estimatedSavingsCard.calculationTable.yearlyInterestAvgCardLabel') }}</p>
                <p>{{ formattedYearlyInterestAvgCard }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <p>{{ $t('components.estimatedSavingsCard.calculationTable.yearlyInterestAvenCardLabel') }}</p>
                <p>{{ formattedYearlyInterestAvenCard }}</p>
            </div>
            <hr class="my-0">
            <div class="d-flex flex-row justify-content-between">
                <p
                    class="fw-bold"
                    v-html="$t('components.estimatedSavingsCard.calculationTable.yearlyEstimatedSavingsLabel')"
                />
                <p class="fw-bold">
                    {{ formattedYearlyEstimatedSavings }}
                </p>
            </div>
        </div>
    </card-block>
</template>

<script>
    import CardBlock from '@/components/CardBlock.vue'
    import { useEstimatedSavingsStore } from '@/store/estimatedSavingsStore'
    import { toFormattedUSD, toFormattedUSDNoCents } from '@/mixins/format'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { PifScreen } from '@/utils/pifManager'
    import { pifNativeShare } from '@/utils/sharedLogic'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import { logger } from '@/utils/logger'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import { useCreditCardMarketDataStore } from '@/store/creditCardMarketDataStore'

    export default {
        name: 'EstimatedSavingsCard',
        components: { CardBlock },
        mixins: [payItForwardMixin],
        props: {
            showTitle: {
                type: Boolean,
                required: false,
                default: true,
            },
            balanceTransferAmountDollars: {
                type: Number,
                required: false,
                default: null,
            },
            cashOutAmountDollars: {
                type: Number,
                required: false,
                default: null,
            },
            noHorizontalPadding: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                showCalculation: false,
                additionalInterestSavingsAmount: null,
            }
        },
        computed: {
            cardTitle() {
                if (this.showTitle) {
                    return this.$t('components.estimatedSavingsCard.title')
                }
                return null
            },
            shouldShowCard() {
                return useEstimatedSavingsStore().hasSavingsWorthShowing
            },
            formattedCurrentBalanceDollars() {
                return this.formattedDollarAmountOrNull(useEstimatedSavingsStore().currentBalanceDollars)
            },
            formattedYearlyInterestAvgCard() {
                return this.formattedDollarAmountOrNull(useEstimatedSavingsStore().estimatedYearlyInterestAvgCardDollars)
            },
            formattedYearlyInterestAvenCard() {
                return this.formattedDollarAmountOrNull(useEstimatedSavingsStore().estimatedYearlyInterestAvenCardDollars)
            },
            formattedYearlyEstimatedSavings() {
                return this.formattedDollarAmountOrNull(useEstimatedSavingsStore().estimatedYearlyInterestSavingsDollars)
            },
            formattedYearlyEstimatedSavingsBulletLabel() {
                const estimatedYearlyInterestSavingsDollars = useEstimatedSavingsStore().estimatedYearlyInterestSavingsDollars
                if (!estimatedYearlyInterestSavingsDollars) {
                    return null
                }
                return toFormattedUSDNoCents(estimatedYearlyInterestSavingsDollars)
            },
            formatted4YearEstimatedSavingsBulletLabel() {
                const estimated4YearSavingsDollars = useEstimatedSavingsStore().estimated4YearInterestSavingsDollars
                if (!estimated4YearSavingsDollars) {
                    return null
                }
                return toFormattedUSDNoCents(estimated4YearSavingsDollars)
            },
            isWebView() {
                return useDeviceInfoStore().isWebView
            },
            additionalInterestSavingsAmountString() {
                if (!this.additionalInterestSavingsAmount) {
                    return null
                } else if (this.balanceTransferAmountDollars) {
                    return this.$t('components.estimatedSavingsCard.balanceTransferAdditionalAmount', {
                        formattedDollarAmount: toFormattedUSD(this.additionalInterestSavingsAmount),
                    })
                } else if (this.cashOutAmountDollars) {
                    return this.$t('components.estimatedSavingsCard.cashOutAdditionalAmount', {
                        formattedDollarAmount: toFormattedUSD(this.additionalInterestSavingsAmount),
                    })
                } else {
                    logger.fatal(`Estimated savings card has additionalInterestSavingsAmount=${this.additionalInterestSavingsAmount}, but no balanceTransferAmountDollars or cashOutAmountDollars`)
                    return null
                }
            },
        },
        async mounted() {
            const estimatedSavingsStore = useEstimatedSavingsStore()
            await estimatedSavingsStore.tryGetEstimatedSavings()

            if (this.balanceTransferAmountDollars || this.cashOutAmountDollars) {
                const additionalBalance = this.balanceTransferAmountDollars ?? this.cashOutAmountDollars
                const originalSavingsAmount = estimatedSavingsStore.estimatedYearlyInterestSavingsDollars
                estimatedSavingsStore.tryAddHypotheticalBalanceAmountDollars(additionalBalance)
                this.additionalInterestSavingsAmount = estimatedSavingsStore.estimatedYearlyInterestSavingsDollars - originalSavingsAmount
                logger.info(
                    `Adding ${this.balanceTransferAmountDollars ? 'balance transfer' : 'cash out'} amount dollars ${additionalBalance} to estimatedSavingsStore yields ${
                        estimatedSavingsStore.estimatedYearlyInterestSavingsDollars
                    }-${originalSavingsAmount}=${this.additionalInterestSavingsAmount} more savings`
                )
            }

            if (this.shouldShowCard) {
                this.$logEvent('render_estimated_savings_card', {
                    ...estimatedSavingsStore.$state,
                    balanceTransferAmountDollars: this.balanceTransferAmountDollars,
                    cashOutAmountDollars: this.cashOutAmountDollars,
                })
                await useCreditCardMarketDataStore().tryLoadCreditCardMarketData()
            } else {
                logger.info(`Not displaying estimated savings card: ${JSON.stringify(estimatedSavingsStore.$state)}`)
            }
        },
        methods: {
            handleInviteFriendsClick: async function () {
                await this.getPayItForwardData(PifScreen.ESTIMATED_SAVINGS_CARD)
                const pifLink = this.prettyPayItForwardShareLink
                const message = this.$t(`sharePifLink.estimatedSavings.${this.rewardType}.body`, {
                    estimatedSavingsAmount: this.formattedYearlyEstimatedSavings,
                    maxAmount: toFormattedUSDNoCents(this.payItForwardMaxAmount),
                    pifLink,
                }).toString()
                this.$logEvent('click_button_estimated_savings_card_invite_friends', {
                    isWebView: this.isWebView,
                    pifLink,
                    message,
                    ...useEstimatedSavingsStore().$state,
                })
                if (this.isWebView) {
                    logger.info(`Estimated savings card invite friends click. Aven my running in native web view, so opening native share sheet w/ link ${pifLink}`)
                    const subject = this.$t(`sharePifLink.${this.rewardType}.subject`, {
                        maxAmount: toFormattedUSDNoCents(this.payItForwardMaxAmount),
                    }).toString()
                    await pifNativeShare({ shareLink: pifLink, subject, message }, this.rewardType)
                } else {
                    logger.info(`Estimated savings card invite friends click. Aven my running on web, so opening ${RoutePaths.PAY_IT_FORWARD} w/ shareTextOverride='${message}'`)
                    await this.$router.push({
                        name: RouteNames.PAY_IT_FORWARD,
                        params: {
                            shareTextOverride: message,
                        },
                    })
                }
            },
            handleToggleCalculationClick: function () {
                this.showCalculation = !this.showCalculation
                this.$logEvent('click_button_estimated_savings_card_toggle_calculation', {
                    showCalculation: this.showCalculation,
                    ...useEstimatedSavingsStore().$state,
                })
            },
            formattedDollarAmountOrNull: function (candidateAmount) {
                if (!candidateAmount) {
                    return null
                }
                return toFormattedUSD(candidateAmount)
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'node_modules/aven_shared/src/styles/variables.scss';

    .card-block {
        background-image: url('../assets/images/components/estimatedSavings/image-confetti.svg');
        background-position: top;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }

    .savings-graph {
        background-image: url('../assets/images/components/estimatedSavings/image-graph.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        aspect-ratio: 2.75;
    }

    .additional-balance-pill {
        background-color: $credit-green;
    }

    p {
        margin-bottom: 0;
    }

    .btn-link:hover {
        color: $black;
    }

    .graph-label {
        color: $credit-green;
        position: absolute;

        &.left {
            width: 72px;
            text-align: center;
            top: 57%;
            left: 14%;
        }

        &.right {
            width: 72px;
            text-align: center;
            top: 0;
            right: -4%;
        }

        // It's messy, but the media queries below keep the labels in line w/ the bullet
        // points they represent.
        @media (min-width: 384px) {
            &.left {
                top: 57%;
                left: 17%;
            }

            &.right {
                top: 2%;
                right: -2%;
            }
        }

        @media (min-width: 480px) {
            &.left {
                top: 60%;
                left: 20%;
            }

            &.right {
                top: 3%;
                right: 1%;
            }
        }

        @media (min-width: 768px) {
            &.left {
                top: 56%;
                left: 17%;
            }

            &.right {
                top: 0;
                right: -1%;
            }
        }
    }
</style>
