import { defineStore } from 'pinia'
import { getAvailableInstantBalanceTransfers, InstantBalanceTransferOption } from '@/services/api'
import { logger } from '@/utils/logger'
import { openReplay } from '@/utils/openReplay'

export const usePersonalFinancialLiabilityStore = () => {
    const personalFinancialLiabilityStore = defineStore('personalFinancialLiabilityStore', {
        state: () => {
            return {
                instantBalanceTransfer: {
                    instantBalanceTransferOptions: [],
                },
            }
        },
        getters: {
            instantBalanceTransferOptions: (state) => state.instantBalanceTransfer.instantBalanceTransferOptions,
        },
        actions: {
            updateInstantBalanceTransferOption(updatedOption: InstantBalanceTransferOption) {
                logger.info(`Updating instant balance transfer option w/ personal financial liability ID ${updatedOption.personalFinancialLiabilityId} to: ${JSON.stringify(updatedOption)}`)
                this.instantBalanceTransfer.instantBalanceTransferOptions = this.instantBalanceTransfer.instantBalanceTransferOptions.map((option) => {
                    if (updatedOption.personalFinancialLiabilityId === option.personalFinancialLiabilityId) {
                        return updatedOption
                    }
                    return option
                })
            },
            async getInstantBalanceTransferOptions() {
                try {
                    logger.info(`Getting instant balance transfer options`)
                    const response = await getAvailableInstantBalanceTransfers()
                    if (!response.data.success) {
                        logger.error(`getAvailableInstantBalanceTransfers failed with ${response.data.error}`)
                        return
                    }
                    this.instantBalanceTransfer.instantBalanceTransferOptions = response.data.payload
                    logger.info(`Got instant balance transfer options ${JSON.stringify(this.instantBalanceTransfer.instantBalanceTransferOptions)}`)
                } catch (e) {
                    logger.error(`Failed to get instant balance transfer options: ${e.message}`)
                    this.instantBalanceTransfer.instantBalanceTransferOptions = []
                }
            },
            getInstantBalanceTransferOption(personalFinancialLiabilityId: number) {
                return this.instantBalanceTransfer.instantBalanceTransferOptions.find((option) => {
                    return option.personalFinancialLiabilityId === personalFinancialLiabilityId
                })
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(personalFinancialLiabilityStore)
    return personalFinancialLiabilityStore
}
