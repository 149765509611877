import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { getRewardsSummary, RewardsRedeemed, syncAscendaAndCoreCard } from '@/services/api'
import { logger } from '@/utils/logger'
import { ref } from 'vue'
import sortBy from 'lodash/sortBy'
import { useOverviewStore } from '@/store/overviewStore'
import { ApiErrorHandler } from '@/utils/exception-handler'
import { i18n } from '@/utils/i18n'

export const useRewardsStore = () => {
    const rewardsStore = defineStore('rewards', () => {
        const loading = ref(false)
        const errorMessage = ref<string | null>(null)
        const remainingRewardsPoints = ref<number | null>(null)
        const rewardsRedeemedSummary = ref<RewardsRedeemed[]>([])
        const canAccessAscenda = ref(false)

        const mustTurnOnAutopayForRewards = (): boolean => {
            const dollarToCashbackRewardPointsConversion = useOverviewStore().dollarToCashbackRewardPointsConversion
            if (dollarToCashbackRewardPointsConversion > 0) {
                logger.info(`customer has dollarToCashbackRewardPointsConversion ${dollarToCashbackRewardPointsConversion}, letting customer redeem`)
                return false
            }
            logger.info(`customer has ${dollarToCashbackRewardPointsConversion} cashback ratio and needs to turn on autopay to earn rewards.`)
            return true
        }

        const tryRetrieveRewardsSummary = async (): Promise<boolean> => {
            if (mustTurnOnAutopayForRewards()) {
                return false
            }
            try {
                logger.info(`Getting rewards summary`)
                const response = await getRewardsSummary()
                if (!response.data.success) {
                    logger.info(`Getting rewards summary failed with ${response.data.error}`)
                    // Todo Not clear whether this error message should be displayed to Cardholders. See
                    //  CoreCardController.avenCoreCardGetRewardsSummary() to analyze
                    errorMessage.value = response.data.error
                    return false
                }
                logger.info(`Got rewards summary ${JSON.stringify(response.data)}`)
                const payload = response.data.payload

                remainingRewardsPoints.value = payload.remainingScaledByCashBackRatio
                logger.info(`Cardholder has ${remainingRewardsPoints.value} remaining scaled rewards points. Response payload: ${JSON.stringify(payload)}`)

                const rewardsRedeemedList = payload.rewardsRedeemedResponse
                if (rewardsRedeemedList?.length) {
                    rewardsRedeemedSummary.value = sortBy(rewardsRedeemedList, 'dateRedeemed')
                    logger.info(`Cardholder has these redeemed rewards: ${JSON.stringify(rewardsRedeemedSummary.value)}`)
                } else {
                    logger.info(`There are no rewards redeemed for this cardholder yet`)
                }
                return true
            } catch (e) {
                logger.error(`Error retrieving rewards summary`, null, e)
                errorMessage.value = ApiErrorHandler(e)
                return false
            }
        }

        const trySyncAscendaAndCoreCard = async () => {
            logger.info(`Trying to sync Ascenda & CoreCard`)
            try {
                const response = await syncAscendaAndCoreCard()
                canAccessAscenda.value = response.data.success && response.data.payload.hasAscendaAccount && response.data.payload.syncCompleted
            } catch (e) {
                logger.error(`Error syncing Ascenda & CoreCard`, null, e)
                canAccessAscenda.value = false
            }
            logger.info(`After syncAscendaAndCoreCard canAccessAscenda=${canAccessAscenda.value}`)
        }

        const tryRefreshAllRewards = async () => {
            if (loading.value === true) {
                logger.info(`Rewards are currently loading, don't need to load again until last load finishes`)
                return
            }

            if (!useOverviewStore().isLoyaltyProduct) {
                logger.info(`Cardholder does not have a loyalty enabled product, so not refreshing rewards. ProductType: ${useOverviewStore().productType}`)
                return
            }

            if (useOverviewStore().isNowCoreCardEndOfDayProcessingTime) {
                logger.info(`It is Core Card's end of day processing time at the moment. Advising customer to retry rewards redemption in 2 hours.`)
                errorMessage.value = i18n.t('pages.rewards.coreCardEndOfDayProcessingError')
                return
            }

            try {
                errorMessage.value = null
                loading.value = true
                logger.info(`Refreshing all rewards`)
                await trySyncAscendaAndCoreCard()
                logger.info(`Synced external rewards ... now retrieving rewards summary`)
                await tryRetrieveRewardsSummary()
                logger.info(`Finished refreshing all rewards`)
            } catch (e) {
                logger.error(`Error refreshing all rewards`, null, e)
            } finally {
                loading.value = false
            }
        }

        return {
            loading,
            errorMessage,
            remainingRewardsPoints,
            rewardsRedeemedSummary,
            canAccessAscenda,
            tryRefreshAllRewards,
        }
    })()
    openReplay.setUpPiniaStoreTracking(rewardsStore)
    return rewardsStore
}
