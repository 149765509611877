<template>
    <header>
        <div
            class="container"
            data-testid="app-header-title"
        >
            <h3 class="heading-3">
                {{ title }}
            </h3>
            <div class="d-flex">
                <div
                    v-if="payItForwardMaxAmount > 0 && rewardType === 'classicPif'"
                    @click="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    @keydown.enter="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    class="btn-pill bg-success text-white me-2"
                >
                    Share {{ payItForwardAmount }}
                </div>
                <img
                    class="help-btn"
                    src="@/assets/images/components/navbar/Help.svg"
                    width="24"
                    height="24"
                    @click="$router.push({ path: '/support', query: { label: label } })"
                    @keydown.enter="$router.push({ path: '/support', query: { label: label } })"
                    alt="Help"
                >
            </div>
        </div>
    </header>
</template>

<script>
    import zendeskMixin from '@/mixins/zendeskMixin'
    import goToPayItForward, { GoToPayItForwardCaller } from '@/mixins/goToPayItForward'
    import format from '@/mixins/format'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import { PifScreen } from '@/utils/pifManager'
    import { RouteNames } from '@/routes/router.types'

    const pathToLabel = [
        {
            name: RouteNames.STATEMENTS,
            label: 'statements',
        },
        {
            name: RouteNames.REWARDS,
            label: 'rewards',
        },
        {
            name: RouteNames.CARD,
            label: 'account',
        },
        {
            name: RouteNames.ACTIVITY,
            label: 'activity',
        },
    ]

    export default {
        // TODO: This is a reserved component name. Please rename it.
        // eslint-disable-next-line vue/no-reserved-component-names
        name: 'Header',
        mixins: [zendeskMixin, goToPayItForward, format, payItForwardMixin],
        props: {
            title: {
                type: String,
                required: true,
            },
        },
        async mounted() {
            await this.getPayItForwardData(PifScreen.HEADER)
        },
        data() {
            return {
                showingSupport: true,
            }
        },
        computed: {
            GoToPayItForwardCaller() {
                return GoToPayItForwardCaller
            },
            label: function () {
                const obj = pathToLabel.find((o) => o.name === this.$route.name)
                return obj ? obj.label : ''
            },
            payItForwardAmount: function () {
                return this.toFormattedUSDNoCents(this.payItForwardMaxAmount)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appHeader';
</style>
