<template>
    <div>
        <app-header
            title="Account"
            class="mobile-header"
        />
        <div class="container px-0 px-md-3 mb-2">
            <card-block
                class="mb-2"
                :title="title"
            >
                <list-row
                    :title="$t('pages.card.AccountDetails')"
                    :show-arrow="true"
                    @onClick="$router.push('accountDetails')"
                    row-icon="images/pages/card/Pen.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-account-details"
                />
                <list-row
                    v-show="showBalanceTransferFeature && !accountIsClosed"
                    :title="$t('pages.card.BalanceTransfer')"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.BALANCE_TRANSFER_REQUEST)"
                    row-icon="images/pages/card/BalanceTransfer.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-balance-transfer"
                />
                <feature-flag
                    name="cashOut"
                    :active="false"
                >
                    <list-row
                        v-show="showCashOutFeature && !accountIsClosed"
                        :title="$t('pages.card.CashOut')"
                        :show-arrow="true"
                        @onClick="$router.push(RoutePaths.CASH_OUT_HISTORY)"
                        row-icon="images/pages/card/Cash.svg"
                        data-testid="cash-out-item"
                        class="mx-0 px-0"
                    />
                </feature-flag>
                <list-row
                    v-show="isLoyaltyProduct"
                    :title="$t('pages.card.Rewards')"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.REWARDS)"
                    row-icon="images/layouts/dashboard/Rewards_Inactive.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-rewards"
                />
                <list-row
                    :title="$t('pages.card.MakeAPayment')"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.MAKE_PAYMENT)"
                    row-icon="images/pages/card/Payment.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-make-payment"
                />
                <list-row
                    :title="$t('pages.card.AutoPay')"
                    :show-arrow="true"
                    @onClick="$router.push('autoPay')"
                    row-icon="images/pages/card/AutoPay.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-auto-pay"
                />
                <list-row
                    :title="$t('pages.card.AdvanceTransactionNotification')"
                    :value="advanceTransactionNotificationDisplayDate"
                    :value-class="['text-muted']"
                    :show-arrow="true"
                    v-show="showAdvanceTransactionNotification"
                    @onClick="$router.push(RoutePaths.ADVANCE_TRANSACTION_NOTIFICATION)"
                    row-icon="images/pages/card/LineSizeIncrease.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-atn"
                />
                <list-row
                    v-show="!cardRequiresActivation && !accountIsClosed"
                    :title="$t('pages.card.ReportLostOrStolen')"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.REPORT_LOST_OR_STOLEN)"
                    row-icon="images/pages/card/Location.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-lost-or-stolen"
                />
            </card-block>

            <!-- Pay It Forward Card -->
            <pay-it-forward-card
                @open-pif-info-dialog="openPifInfoModal"
                v-if="payItForwardMaxAmount > 0"
                class="mt-2 mb-2"
                :screen-name="PifScreen.ACCOUNT"
                data-testid="button-view-pay-it-forward"
            />

            <card-block title="Settings">
                <list-row
                    :title="$t('pages.accountDetails.PersonalDetails')"
                    show-arrow
                    @onClick="$router.push(RoutePaths.PERSONAL_DETAILS)"
                    row-icon="images/pages/card/Pen.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-settings"
                />
                <list-row
                    v-if="isPreview"
                    :title="$t('pages.accountDetails.CreditScore')"
                    show-arrow
                    @onClick="$router.push(RoutePaths.CREDIT_SCORE_MONITORING)"
                    row-icon="images/pages/card/CreditScore.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    v-show="!accountIsClosed"
                    :title="$t('pages.accountDetails.PaymentSources')"
                    show-arrow
                    @onClick="$router.push('paymentSource')"
                    row-icon="images/pages/card/BankAccount.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-payment-sources"
                />
                <list-row
                    v-show="isWebView"
                    :title="$t('pages.accountDetails.NotificationSettings')"
                    show-arrow
                    @onClick="$router.push(RoutePaths.NOTIFICATION_SETTING)"
                    row-icon="images/pages/card/Bell.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    :title="$t('pages.documentsAndNotices.title')"
                    show-arrow
                    @onClick="$router.push(RoutePaths.DOCUMENTS)"
                    row-icon="images/pages/card/DocumentMoney.svg"
                    class="mx-0 px-0"
                    data-testid="button-view-documents"
                />
                <list-row
                    :title="$t('pages.card.LogOut')"
                    :show-arrow="true"
                    @onClick="logout"
                    row-icon="images/pages/card/Logout.svg"
                    class="mx-0 px-0"
                    data-testid="button-log-out"
                />
                <list-row
                    v-if="isTestingEnvironment"
                    title="Testing Overrides (Non-Production Only)"
                    show-arrow
                    @onClick="$router.push(RoutePaths.TEST_MODES)"
                    row-icon="images/pages/card/BalanceTransfer.svg"
                    class="mx-0 px-0"
                />
            </card-block>
        </div>
        <modal
            :show="showPifInfoModal"
            :title="$t('pages.payItForwardContactList.infoModalTitle')"
            @close="closePifInfoModal"
        >
            <p
                class="text-center mb-3"
                v-html="$t(`components.pifFooter.${rewardType}.content`)"
            />
            <base-button @click="closePifInfoModal">
                {{ $t('pages.payItForwardContactList.infoModalClose') }}
            </base-button>
        </modal>
    </div>
</template>

<script>
    import Header from '../components/AppHeader'
    import ListRow from '@/components/ListRow'
    import FeatureFlag from '@/components/FeatureFlag'
    import CardBlock from '@/components/CardBlock'
    import goToPayItForward, { GoToPayItForwardCaller } from '@/mixins/goToPayItForward'
    import format from '@/mixins/format'
    import { useOverviewStore } from '@/store/overviewStore'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { RoutePaths } from '@/routes/router.types'
    import { AvenCardProductType } from '@/store/overviewStore.types'
    import { useCashOutAndBalanceTransferStore } from '@/store/cashOutAndBalanceTransferStore'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import PayItForwardCard from '@/components/PayItForwardCard.vue'
    import Modal from '@/components/Modal.vue'
    import BaseButton from '@/components/base/BaseButton.vue'
    import { PifScreen } from '@/utils/pifManager'
    import { DebtProductAndMetaDataSet } from '@/services/avenAppApi'
    import dayjs from 'dayjs'
    import { MMM_DD_DATE_FORMAT } from '@/data/constants'

    export default {
        name: 'Card',
        components: {
            BaseButton,
            Modal,
            PayItForwardCard,
            CardBlock,
            ListRow,
            'app-header': Header,
            FeatureFlag,
        },
        data: function () {
            return {
                RoutePaths,
                showPifInfoModal: false,
            }
        },
        mixins: [goToPayItForward, format, payItForwardMixin],
        computed: {
            PifScreen() {
                return PifScreen
            },
            GoToPayItForwardCaller() {
                return GoToPayItForwardCaller
            },
            isWebView() {
                return useDeviceInfoStore().isWebView
            },
            isPreview() {
                return useOverviewStore().isInternalEmployee || process.env.NODE_ENV === 'development'
            },
            accountIsClosed() {
                return useOverviewStore().accountIsClosed
            },
            showCashOutFeature() {
                return useOverviewStore().showCashOutFeature
            },
            showBalanceTransferFeature() {
                return useOverviewStore().showBalanceTransferFeature
            },
            showAdvanceTransactionNotification: function () {
                return useOverviewStore().advanceTransactionNotification.isEligibleToCreate || !!useOverviewStore().advanceTransactionNotification.mostRecentOngoing
            },
            advanceTransactionNotificationDisplayDate: function () {
                if (!useOverviewStore().advanceTransactionNotification.mostRecentOngoing) {
                    return undefined
                }
                return `${dayjs(useOverviewStore().advanceTransactionNotification.mostRecentOngoing.advanceTransactionNotificationFrom).format(MMM_DD_DATE_FORMAT)} - ${dayjs(
                    useOverviewStore().advanceTransactionNotification.mostRecentOngoing.advanceTransactionNotificationTo
                ).format(MMM_DD_DATE_FORMAT)}`
            },
            cardRequiresActivation() {
                return useOverviewStore().cardRequiresActivation
            },
            apr() {
                return useOverviewStore().apr
            },
            isLoyaltyProduct() {
                return useOverviewStore().isLoyaltyProduct
            },
            isTestingEnvironment() {
                return useOverviewStore().isTestingEnvironment
            },
            payItForwardAmount: function () {
                return this.toFormattedUSDNoCents(this.payItForwardMaxAmount)
            },
            productType() {
                return useOverviewStore().productType
            },
            title() {
                if (this.productType === AvenCardProductType.AUTO) {
                    return this.$t('pages.card.AutoTitle')
                } else {
                    return this.$t('pages.card.HomeTitle')
                }
            },
        },
        mounted: async function () {
            this.$logEvent('view_card')
            // for consistency since app's will attempt to invoke this method when view is foregrounded.
            window.refreshData = this.refreshData
            await this.getPayItForwardData(PifScreen.CARD)
        },
        methods: {
            // for consistency since app's will attempt refresh when view is foregrounded.
            refreshData: async function () {
                await useOverviewStore().updateAccountOverview({
                    debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all,
                })
                await useCashOutAndBalanceTransferStore().getExistingBalanceTransfers()
            },
            logout: async function () {
                this.$logEvent('click_logout')
                await this.$router.replace(RoutePaths.LOGOUT)
                // If Aven My is running in a native app shell, we need to reload the page with the
                // RoutePaths.LOGOUT route because that communicates to the native shell that the
                // customer is logging out, so it can execute its own logout logic.
                if (useDeviceInfoStore().isSingleWebView) {
                    window.location.href = RoutePaths.LOGOUT
                }
            },
            openPifInfoModal: function () {
                this.showPifInfoModal = true
            },
            closePifInfoModal: function () {
                this.showPifInfoModal = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appBase';
</style>
