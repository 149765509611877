<template>
    <div
        ref="toggler"
        class="dropdown"
        @click="toggleDropdown"
        @keydown="toggleDropdown"
    >
        <slot name="toggler" />
        <div
            v-show="isOpen"
            class="aven-dropdown-menu"
            ref="dropdown"
        >
            <ul class="ul-no-deco my-1 mx-3">
                <li
                    v-for="item in menuItems"
                    :key="item.text"
                    @click="item.clickHandler"
                >
                    {{ item.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    /**
     * Primary component for Dropdown functionality
     */
    export default {
        name: 'Dropdown',
        data() {
            return {
                isOpen: false,
            }
        },
        props: {
            menuItems: {
                type: Array,
                default: () => [],
                required: false,
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside)
        },
        methods: {
            toggleDropdown() {
                this.isOpen = !this.isOpen
            },
            handleClickOutside(event) {
                if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target) && this.$refs.toggler && !this.$refs.toggler.contains(event.target)) {
                    this.isOpen = false
                }
            },
        },
    }
</script>

<!--TODO: figure out how to allow for scoped styles in aven_shared-->
<!-- https://github.com/vitejs/vite/issues/1579 -->
<!-- https://github.com/emosheeep/fe-tools/tree/HEAD/packages/vite-plugin-lib-inject-css#readme -->
<!--<style lang="scss" scoped>-->
<!--  @import '../styles/components/dropdown';-->
<!--</style>-->
