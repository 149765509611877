<template>
    <div>
        <product-selection
            v-if="showProductSelection"
            :product-info-response="productInfoResponse"
            :on-submit="tryLoginOnSuccessfulBiometrics"
        />
        <div v-else>
            <div
                v-if="!askConfirmationCode"
                data-testid="login-group"
            >
                <h5
                    class="mb-4"
                    v-html="$t('pages.login.signInTitle')"
                />
                <form-container
                    ref="phoneForm"
                    @onSubmit="submitPhoneNumber"
                    id="login-form"
                    data-testid="login-form"
                >
                    <form-field-phone
                        ref="phoneField"
                        v-model="customerContactInfo"
                        name="phoneField"
                        classes="text-center"
                        class="mb-2"
                        input-type="number"
                        validation-rules="required|phoneNumberRule"
                        validation-mode="lazy"
                        :autofocus="true"
                        :placeholder="$t('pages.login.phonePlaceholder')"
                        :label="$t('pages.login.phonePlaceholder')"
                        data-testid="login-form_phone-input"
                    />
                    <base-button
                        type="submit"
                        :submitting="submitting"
                        data-testid="login-form_submit"
                    >
                        {{ $t('pages.login.signInOrActivate') }}
                    </base-button>
                </form-container>
            </div>
            <div
                v-else
                data-testid="confirmation-group"
            >
                <h5
                    class="mb-4"
                    v-html="verifyCodeMessage"
                />
                <form-container
                    ref="confirmPhoneForm"
                    @onSubmit="verifyOtpCode"
                    data-testid="confirm-form"
                >
                    <form-field
                        ref="confirmationCode"
                        v-model="confirmationCode"
                        name="confirmationCode"
                        validation-rules="required|twilioCode|min:6|max:6"
                        input-type="tel"
                        inputmode="decimal"
                        classes="text-center"
                        autocomplete="new-password"
                        :autofocus="true"
                        :max-length="twilioCodeLength"
                        class="mb-2"
                        :placeholder="$t('pages.login.codePlaceholder')"
                        :label="$t('pages.login.codePlaceholder')"
                        data-testid="confirm-form_code-input"
                    />
                    <base-button
                        :submitting="submitting"
                        type="submit"
                        data-testid="confirm-form_submit"
                    >
                        {{ $t('pages.login.continue') }}
                    </base-button>
                </form-container>
            </div>
            <div class="other-options mt-1">
                <div v-if="!biometricsEnabled || askConfirmationCode">
                    <button
                        class="btn btn-tertiary"
                        @click="showHelpModal"
                        @keydown.enter="showHelpModal"
                    >
                        {{ $t('pages.login.Help') }}
                    </button>
                </div>
                <div v-else>
                    <button @click="clickUnlockUsingBiometrics">
                        {{ $t('pages.login.biometrics') }}
                    </button>
                    &nbsp;•&nbsp;
                    <button @click="showHelpModal">
                        {{ $t('pages.login.Help') }}
                    </button>
                </div>
            </div>
            <p class="small text-muted text-center mt-2">
                By continuing, you agree to our
                <a
                    href="https://www.aven.com/docs/PrivacyPolicy.html"
                    target="_blank"
                    class="text-underline"
                > Privacy Policy </a>
                <br>
                v{{ appVersion }}
            </p>

            <modal
                :title="$t('pages.login.LoginHelp')"
                :show="helpModalVisibility && askConfirmationCode"
                @close="helpModalVisibility = false"
            >
                <p class="text-center mb-3">
                    {{ $t('pages.login.helpDescription') }}
                </p>
                <base-button
                    class="mt-2"
                    @click="resendCode"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.resendCode') }}
                </base-button>
                <base-button
                    class="mt-2"
                    @click="sendOtpToCall"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.sendToCall') }}
                </base-button>
                <base-button
                    class="mt-2"
                    @click="sendOtpToEmail"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.sendToEmail') }}
                </base-button>
                <base-button
                    class="mt-2"
                    @click="changeNumber"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.ChangeNumber') }}
                </base-button>
            </modal>
            <help
                :title="$t('pages.login.loginHelpModal.title')"
                :visibility="helpModalVisibility && !askConfirmationCode"
                @close="helpModalVisibility = false"
                @click="sendSupportText"
            />
        </div>
    </div>
</template>

<script lang="js">
    import LogRocket from 'logrocket';
    import { openReplay } from '@/utils/openReplay'
    import { appSessionStorage, localStorageKey } from "@/utils/storage";
    import { helpModalMixin } from '@/mixins/helpModalMixin'
    import FormFieldPhone from "@/components/base/FormFieldPhone";
    import BaseButton from "@/components/base/BaseButton";
    import FormContainer from "@/components/base/FormContainer";
    import generic from "@/utils/generic";
    import { logger } from "@/utils/logger";
    import { i18n } from "@/utils/i18n";
    import Modal from "@/components/Modal";
    import { FormField } from "aven_shared/dist/components";
    import {
        AvenMyNextAction,
        blockStatus, createOriginationLead, createOriginationSession, getAvenMyNextActionFromPhoneNumber,
        PasswordLoginErrors,
        requestOtpCode, TwilioErrorCodes,
        verifyOtpCode
    } from '@/services/avenAppApi'
    import loginMixin, { AppBlockedError, BiometricsError } from "@/mixins/loginMixin";
    import inspect from "util-inspect";
    import { getDeviceInfo } from "@/utils/deviceUtils";
    import { getRootAndDeeplinkFromPath } from '@/routes/router'
    import { TWILIO_ERROR_TO_I18N_NAMES, TwoFactorCodeDeliveryMethod } from '@/data/constants'
    import {
        checkIfShouldNavigateToPushNotificationPermission,
        NATIVE_MESSAGES,
        sendMessageToNativeApp
    } from '@/utils/sharedLogic'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { useSessionStore } from '@/store/sessionStore'
    import { StatusCodes } from 'http-status-codes'
    import { useOverviewStore } from '@/store/overviewStore'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { useGlobalUiStore } from '@/store/globalUiStore'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import ProductSelection from '@/views/ProductSelection.vue'

    export default {
        components: {
            ProductSelection,
            BaseButton,
            FormContainer,
            FormFieldPhone,
            Modal,
            FormField,
        },
        mixins: [helpModalMixin, loginMixin],
        data() {
            return {
                loading: false,
                customerContactInfo: "",
                displayContactInfo: "",
                customerEmail: "",
                customerPhoneNumber: "",
                confirmationCode: "",
                askConfirmationCode: false,
                submitting: false,
                biometricsLogin: false,
                sid: "",
                helpModalVisibility: false,
                twilioCodeLength: 6,
                showBiometricsPromptOnMount: false,
                RouteNames,
                password: '',
                showProductSelection: false,
                productInfoResponse: null,
            }
        },
        async created() {
            document.addEventListener('visibilitychange', this.onVisibilityChange)
            logger.info(`Login.vue is created, added visibilitychange event listener`)

            // The native apps create Login.vue with a bootstrapData query param, which
            // communicates important information about the device that mSite is running in.
            if (this.$route.query.bootstrapData) {
                logger.info(`Login.vue, created(), received queryParam bootStrapData: ${JSON.stringify(this.$route.query.bootstrapData)}`)
                const deviceInfoStore = useDeviceInfoStore()
                deviceInfoStore.isSingleWebView = true
                deviceInfoStore.isWebView = true

                let bootStrapData = JSON.parse(this.$route.query.bootstrapData)
                deviceInfoStore.setDeviceInfo(bootStrapData.deviceInfo)
                deviceInfoStore.setDeviceCapabilities(bootStrapData.deviceCapabilities)
                appSessionStorage.setItem(localStorageKey.creditCardCustomerId, bootStrapData.creditCardCustomerId)
                this.showBiometricsPromptOnMount = bootStrapData.showBiometricsPrompt || false
                logger.info(`Boostrap data indicates we should ${this.showBiometricsPromptOnMount ? '' : 'not'} show biometrics prompt on mount`)

                // Only set this var if it is currently unset AND we have non-null bootstrap data that says otherwise
                if (this.showLocationPromptOnAppStart === null && bootStrapData.showLocationPrompt) {
                    logger.log(`showLocationPromptOnAppStart is null, updating to match explicit bootstrap value of: ${bootStrapData.showLocationPrompt}`)
                    useGlobalUiStore().showLocationPromptOnAppStart = bootStrapData.showLocationPrompt
                } else {
                    logger.log(`showLocationPromptOnAppStart value of: ${this.showLocationPromptOnAppStart} is not null OR bootstrap value is also null: ${bootStrapData.showLocationPrompt}. Not updating.`)
                }

                if (bootStrapData.deeplinkPath) {
                    logger.info(`Bootstrap data contains deeplinkPath ${bootStrapData.deeplinkPath}`)
                    const result = getRootAndDeeplinkFromPath(bootStrapData.deeplinkPath)
                    logger.info(`Result of getting root & deeplink from ${bootStrapData.deeplinkPath}: ${JSON.stringify(result)}`)
                    const globalUiStore = useGlobalUiStore()
                    globalUiStore.setCurrentRootPath(result.root)
                    globalUiStore.updateDeeplinkPath(result.deeplink)
                }

                if (bootStrapData.sessionId && bootStrapData.sessionAccessJWT) {
                    logger.log(`Native shell provided a sessionId and sessionAccessJWT like we expected`)
                    await useSessionStore().inflateSession(
                        bootStrapData.sessionId,
                        bootStrapData.sessionAccessJWT,
                        useDeviceInfoStore().$state,
                        bootStrapData.creditCardCustomerId
                    )
                } else {
                    // Todo When mSite is running in a native app shell, we expect the native app to provide a session.
                    //  I *think* we should be able to always rely on this happening if Login.vue is loaded because the
                    //  only reason the native shell would be unable to get a session ID is if it doesn't have a network
                    //  connected. Obviously there are network connectivity timing conditions that could legitimately
                    //  preclude the native app from getting a session, but not stop mSite from loading, but I'm not sure
                    //  how often this will happen. Since logger.error() pages on-call, it'll tell us real quickly.
                    logger.error(`Tell Justin P: mSite is running in a native shell, but native app did not provide a session`)
                    await useSessionStore().beginSession()
                }
            }
            // W/ out the bootstrapData query param, we know we aren't running in a native app shell
            else {
                logger.info(`Login.vue mounted, missing bootstrap data, so mSite is running in a browser`)
                const info = await getDeviceInfo()
                useDeviceInfoStore().setDeviceInfo(info)
                logger.info(`App Mounted, set deviceInfo: ${JSON.stringify(info)}`)

                await useSessionStore().beginSession()
                logger.info(`Login.vue, created(), no query params, ${JSON.stringify(this.$route.query)}`)
            }
            await this.initializeDeviceInfoAndCheckBlockStatus()

            this.$logEvent('view_aven_my_login')
        },
        async mounted() {
            this.$nextTick(() => this.$refs.phoneField?.setFocus())

            logger.info(`Login.vue mount started`)

            if (this.showLocationPromptOnAppStart) {
                logger.log(`Got showLocationPromptOnAppStart = true, showing secureWithLocation page and skipping biometrics popup`)
                await this.$router.push({
                    name: RouteNames.SECURE_WITH_LOCATION
                })

                logger.log('Setting location.href to /mounted to notify native app to disable loading banner')
                await sendMessageToNativeApp(NATIVE_MESSAGES.MOUNTED, `routeName=${RouteNames.LOGIN}`)

                // If you trigger the biometrics popup on top of the location screen, things get weird
                // This will trigger a second time when the user returns from secureWithLocation
                // and showLocationPromptOnAppStart will be false, ergo good to go :-)
                return
            }

            logger.log(`Got showLocationPromptOnAppStart = false, not going to show secureWithLocation page`)

            if (this.isSingleWebView) {
                logger.log('Setting location.href to /mounted to notify native app to disable loading banner')
                await sendMessageToNativeApp(NATIVE_MESSAGES.MOUNTED, `routeName=${RouteNames.LOGIN}`)
            }

            if (this.showBiometricsPromptOnMount) {
                logger.info(`Login.vue showBiometricsPromptOnMount = true, so maybe displaying biometrics prompt`)
                await this.maybeDisplayBiometricsPrompt()
            }
        },
        async destroyed() {
            window.removeEventListener('visibilitychange', this.onVisibilityChange)
            logger.info(`Login.vue is destroyed, removed visibilitychange event listener`)
        },
        computed: {
            showLocationPromptOnAppStart() {
                // Disabled for now to appease google play reviewers
                return false
                // return useGlobalUiStore().showLocationPromptOnAppStart
            },
            verifyCodeMessage() {
                return i18n.t('pages.login.codeHeadline', { contactInfo: this.displayContactInfo })
            },
            isSingleWebView() {
                return useDeviceInfoStore().isSingleWebView
            },
            biometricsEnabled() {
                return useDeviceInfoStore().biometricsEnabled
            },
            platform() {
                return useDeviceInfoStore().platform
            },
            deviceGuid() {
                return useDeviceInfoStore().deviceGuid
            },
            appVersion() {
                return useDeviceInfoStore().appVersion
            },
        },
        methods: {
            maybeDisplayBiometricsPrompt: async function () {
                if (this.biometricsEnabled) {
                    logger.log('Got biometricsEnabled = true, going to show native biometrics login')
                    await this.unlockUsingBiometrics()
                } else {
                    logger.log('Got biometricsEnabled = false, not going to show native biometrics login')
                }
            },
            onVisibilityChange: async function () {
                if (!document.hidden && this.$router.currentRoute.path === RoutePaths.LOGIN) {
                    logger.info(`Login.vue visibility change to hidden = ${document.hidden} and path is ${this.$router.currentRoute.path}, displaying biometric prompt if necessary`)
                    await this.maybeDisplayBiometricsPrompt()
                } else if (!document.hidden) {
                    logger.info(`Login.vue visibility change to hidden = ${document.hidden}, but path is ${this.$router.currentRoute.path} (not ${RoutePaths.LOGIN}), not displaying biometric prompt`)
                } else {
                    logger.info(`Login.vue visibility change to hidden = ${document.hidden}`)
                }
            },
            initializeDeviceInfoAndCheckBlockStatus: async function () {
                if (this.isSingleWebView) {
                    window.onBiometricsComplete = this.onBiometricsComplete
                    logger.info(`window.onBiometricsComplete has been set`)
                }

                const response = await blockStatus({ platform: this.platform, deviceGuid: this.deviceGuid, appVersion: this.appVersion })
                if (response.data?.error === 'APP_BLOCKED_ERROR') {
                    logger.log(`App is blocked! ${response.data.payload}`)
                    await this.presentAppBlockedView(response.data.payload)
                } else {
                    logger.log(`App is not blocked, continuing`)
                }
            },
            submitPhoneNumber: async function () {
                const isValid = await this.$refs.phoneForm.$refs.observer.validate()
                if (!isValid) {
                    return
                }

                this.$logEvent('click_aven_my_login_submit_phone_number')
                this.submitting = true

                const userEnteredEmail = generic.validEmail(this.customerContactInfo)
                this.customerPhoneNumber = userEnteredEmail ? null : this.customerContactInfo
                this.customerEmail = userEnteredEmail ? this.customerContactInfo : null

                try {
                    const { data } = await getAvenMyNextActionFromPhoneNumber(this.customerPhoneNumber)
                    if (data.payload.nextAction === AvenMyNextAction.HOME_OG_FLOW) {
                        await this.navigateToAvenHome()
                    } else {
                        await this.requestOtpCode()
                    }
                } catch (error) {
                    logger.error(`Error in submitPhoneNumber: ${error}`)
                    this.applyGeneralErrorOnPhoneForm(i18n.t('global.errors.generic'))
                }
            },
            navigateToAvenHome: async function () {
                this.$logEvent('click_aven_my_login_navigate_to_aven_home')
                let url = process.env.VUE_APP_AVEN_URL

                try {
                    logger.info(`Creating origination session and lead`)
                    const sessionId = useSessionStore().sessionId
                    await createOriginationSession(sessionId)
                    const leadResponse = await createOriginationLead(this.customerPhoneNumber, sessionId)
                    logger.info(`Created origination lead`)

                    logger.info(`Injecting origination auth info to native`)
                    const { applicantId, returnLink, jwtTokens } = leadResponse.data.payload
                    appSessionStorage.setItem(localStorageKey.applicantId, applicantId)
                    appSessionStorage.setItem(localStorageKey.accountPhoneNumber, this.customerPhoneNumber)

                    if (useDeviceInfoStore().canAskForOriginationPushNotifs) {
                        await this.injectOriginationAuthInfoToNative(jwtTokens)
                        logger.info(`Injected origination auth info to native`)
                    }

                    url = returnLink || url
                } catch (error) {
                    logger.error(`Error creating origination lead: ${error}`)
                } finally {
                    // Ensure the navigation happens after all the async operations
                    const urlObj = new URL(url)
                    urlObj.searchParams.append('utm_source', 'aven_my')
                    url = urlObj.toString()

                    const shouldNavigateToPushNotificationPermission = await checkIfShouldNavigateToPushNotificationPermission()
                    if (shouldNavigateToPushNotificationPermission) {
                        logger.info(`Navigating to push notification permission page with post-navigation url: ${url}`)
                        await this.$router.replace({ name: RouteNames.PUSH_NOTIFICATION_PERMISSION, params: { isOrigination: true, navigationUrl: url } })
                    } else {
                        logger.info(`Navigating to ${url}`)
                        window.location.href = url
                    }
                }
            },
            requestOtpCode: async function () {
                this.$logEvent('click_aven_my_login_request_otp_code')
                const isValid = await this.$refs.phoneForm.$refs.observer.validate()
                if (!isValid) {
                    logger.info(`Login.vue is requesting an OTP code, but form is invalid`)
                    return
                }
                logger.info(`Login.vue is requesting an OTP code`)
                this.clearGeneralError()

                await this.otpRequest(this.getOtpPayloadByDeliveryMethod(this.customerPhoneNumber ? TwoFactorCodeDeliveryMethod.sms : TwoFactorCodeDeliveryMethod.email))
            },
            otpRequest: async function (requestOtpPayload) {
                logger.info('Login: loginRequest() starting ' + JSON.stringify(requestOtpPayload))
                try {
                    const response = await requestOtpCode(requestOtpPayload)
                    logger.info('Login.vue: otp request succeeded')
                    if (openReplay.openReplayTracker) {
                        openReplay.trySetMetadata({
                            creditCardCustomerId: appSessionStorage.getItem(localStorageKey.creditCardCustomerId),
                        })
                    } else {
                        LogRocket.identify(useSessionStore().sessionId, {
                            creditCardCustomerId: appSessionStorage.getItem(localStorageKey.creditCardCustomerId),
                        })
                    }

                    if (response.data.success) {
                        logger.info(`Login.vue successfully requested OTP code`)
                        const storageKey = requestOtpPayload.codeDeliveryMethod === TwoFactorCodeDeliveryMethod.email ? localStorageKey.displayEmail : localStorageKey.displayPhoneNumber
                        this.displayContactInfo = appSessionStorage.getItem(storageKey)
                        this.sid = response.data.payload.sid
                        this.askConfirmationCode = true;
                        this.submitting = false
                        this.$nextTick(() => this.$refs.confirmationCode.setFocus());
                    } else {
                        this.submitting = false
                        logger.error(`Login.vue did not successfully request OTP code: ${response.data.error}`)
                        if (response.data.error === "INVALID_US_PHONE_NUMBER_ERROR") {
                            this.applyGeneralErrorOnPhoneForm(i18n.t('pages.login.notValidPhoneNumber'))
                        } else if (response.data.error === "TWILIO_ERROR") {
                            // TODO: handle specific codes, response.data.twilioErrorCode
                            // twilio verify v2 lack documentation on commonly occurring codes.
                            // display generic error for now. will add as we encounter codes
                            // https://www.twilio.com/docs/api/errors
                            logger.info(`Error: /aven_app/2FARequestCode ${JSON.stringify(response.data)}`)
                            const twilioCode = response.data.payload?.twilio_code
                            if (twilioCode === TwilioErrorCodes.PHONE_CANNOT_RECEIVE_SMS) {
                                this.applyGeneralErrorOnPhoneForm(i18n.t('pages.login.twilio.cannotReceiveSms'))
                            } else {
                                this.applyGeneralErrorOnPhoneForm(i18n.t('global.errors.generic'))
                            }
                        }
                    }
                } catch (error) {
                    this.submitting = false
                    if (error?.response?.data?.error === "ERROR_NOT_FOUND") {
                        logger.info(`Aven customer not found during OTP request with payload: ${JSON.stringify(requestOtpPayload)}. Error: ${error.message}`)
                        appSessionStorage.clear()
                        this.applyHtmlErrorOnConfirmPhoneForm(i18n.t('pages.login.notFound'))
                    } else {
                        logger.fatal(`Error submitting OTP request with payload: ${JSON.stringify(requestOtpPayload)}`, error)
                        this.applyGeneralErrorOnPhoneForm(i18n.t('global.errors.generic'))
                    }
                }
            },
            verifyOtpCode: async function () {
                this.$logEvent('click_aven_my_login_verify_otp_code')
                const isValid = await this.$refs.confirmPhoneForm.$refs.observer.validate()
                logger.log(`loginAuth input is ${isValid}`)
                if (!isValid) {
                    logger.info(`Login.vue is verifying OTP code, but form is invalid`)
                    return
                }
                logger.info(`Login.vue is verifying OTP code`)
                try {
                    this.clearGeneralError()
                    this.submitting = true
                    const payload = {
                        verificationCode: this.confirmationCode,
                        sid: this.sid,
                    }
                    const response = await verifyOtpCode(payload)
                    this.submitting = false
                    if (response.data.success) {
                        appSessionStorage.setItem(localStorageKey.accountPhoneNumber, this.customerPhoneNumber)
                        logger.log(`Phone number verification complete for ${this.customerPhoneNumber}`);
                        useOverviewStore().isPasscodeSet = response.data.payload.isPasscodeSet
                        this.$logEvent('event_aven_my_login_verify_otp_code_success')
                        if (response.data.payload.isPasscodeSet) {
                            await this.$router.push({
                                name: RouteNames.PASSWORD_LOGIN
                            })
                        } else {
                            await this.$router.push({
                                name: RouteNames.SET_PASSWORD
                            })
                        }
                    } else {
                        this.$logEvent('event_aven_my_login_verify_otp_code_failure')
                        this.handleVerifyOtpErrorResponse(response.data)
                    }
                } catch (error) {
                    this.$logEvent('event_aven_my_login_verify_otp_code_failure')
                    logger.fatal(`Error verifying OTP code`, error)
                    this.submitting = false
                    this.applyGeneralErrorOnConfirmPhoneForm(i18n.t('global.errors.generic'))
                }
            },
            handleVerifyOtpErrorResponse(data) {
                logger.info(`verify otp response.data: ${JSON.stringify(data)}`)
                if (data.error === "TWILIO_2FA_INCORRECT_CODE") {
                    this.applyGeneralErrorOnConfirmPhoneForm(i18n.t('pages.login.verificationNotCorrect'))
                } else if (data.error === "ERROR_NOT_FOUND") {
                    this.applyHtmlErrorOnConfirmPhoneForm(i18n.t('pages.login.notFound'))
                } else if (data.error === "TWILIO_ERROR") {
                    // TODO: handle specific codes, response.data.twilioErrorCode
                    // twilio verify v2 lack documentation on commonly occurring codes.
                    // display generic error for now. will add as we encounter codes
                    // https://www.twilio.com/docs/api/errors
                    const twilioErrorCode = data.payload.twilioErrorCode

                    let errorMessage = i18n.t('twilioError.generic')
                    if (TWILIO_ERROR_TO_I18N_NAMES.has(twilioErrorCode)) {
                        errorMessage = i18n.t(TWILIO_ERROR_TO_I18N_NAMES.get(twilioErrorCode))
                    }
                    this.askConfirmationCode = false
                    this.applyGeneralErrorOnPhoneForm(errorMessage)

                } else {
                    logger.error(`Unexpected error :${data.error}`)
                    throw new Error(data.error)
                }
            },
            applyGeneralErrorOnPhoneForm(errorMessage) {
                logger.info(`Login.vue applyGeneralErrorOnPhoneForm: ${errorMessage}`)
                this.$nextTick(() => this.$refs.phoneForm.applyGeneralError(errorMessage))
            },
            applyGeneralErrorOnConfirmPhoneForm(errorMessage) {
                logger.info(`Login.vue applyGeneralErrorOnConfirmPhoneForm: ${errorMessage}`)
                this.$nextTick(() => this.$refs.confirmPhoneForm.applyGeneralError(errorMessage))
            },
            applyHtmlErrorOnConfirmPhoneForm(errorMessage) {
                logger.info(`Login.vue applyHtmlErrorOnConfirmPhoneForm: ${errorMessage}`)
                this.$nextTick(() => this.$refs.phoneForm.applyHtmlError(errorMessage))
            },
            clearGeneralError() {
                logger.info("Login.vue clearGeneralError")
                this.$refs.phoneForm?.clearErrorMessage()
                this.$refs.confirmPhoneForm?.clearErrorMessage()
            },
            showHelpModal() {
                this.$logEvent('click_aven_my_login_help')
                // there are two help modals on this screen
                // call support help modal when screen is in phone number input state
                // when screen is in otp verification state, a modal with {resend, send to email, change phone}
                this.helpModalVisibility = true
            },
            async resendCode() {
                this.$logEvent('click_aven_my_login_help_resend_code')
                logger.info('Login: resendCode() starting')
                this.helpModalVisibility = false
                await this.otpRequest(this.getOtpPayloadByDeliveryMethod(this.customerPhoneNumber ? TwoFactorCodeDeliveryMethod.sms : TwoFactorCodeDeliveryMethod.email))
                logger.info('Login: resendCode() finished')
            },
            changeNumber() {
                this.$logEvent('click_aven_my_login_help_change_number')
                this.customerContactInfo = ''
                this.customerPhoneNumber = ''
                this.customerEmail = ''
                this.$refs.phoneField?.clearValue()
                this.$refs.phoneForm?.clearErrorMessage()
                this.helpModalVisibility = false
                this.askConfirmationCode = false
                appSessionStorage.clear()
            },
            sendOtpToEmail() {
                this.$logEvent('click_aven_my_login_help_send_otp_to_email')
                this.submitting = true
                this.otpRequest(this.getOtpPayloadByDeliveryMethod(TwoFactorCodeDeliveryMethod.email))
                this.helpModalVisibility = false
            },
            sendOtpToCall() {
                this.$logEvent('click_aven_my_login_help_send_otp_to_call')
                this.submitting = true
                this.otpRequest(this.getOtpPayloadByDeliveryMethod(TwoFactorCodeDeliveryMethod.call))
                this.helpModalVisibility = false
            },
            presentAppBlockedView: function (payload) {
                logger.log(`Login.vue appBlocked config: ${JSON.stringify(payload)}`)
                let { blockEvent } = payload
                if (!blockEvent) {
                    blockEvent = payload
                }
                if (payload.url) {
                    window.location.href = blockEvent.url
                } else {
                    this.$router.push({
                        name: "AppBlocked",
                        params: {
                            title: blockEvent.title,
                            description: blockEvent.description,
                            buttonText: blockEvent.buttonText,
                            buttonActionUrl: blockEvent.buttonActionUrl,
                        }
                    })
                }
            },
            getOtpPayloadByDeliveryMethod: function (deliveryMethod) {
                logger.info(`get otp payload by delivery method: ${deliveryMethod}`)
                switch (deliveryMethod) {
                    case TwoFactorCodeDeliveryMethod.sms:
                        return {
                            phoneNumber: this.customerPhoneNumber,
                            deviceGuid: this.deviceGuid,
                            codeDeliveryMethod: TwoFactorCodeDeliveryMethod.sms
                        }
                    case TwoFactorCodeDeliveryMethod.call:
                        return {
                            phoneNumber: this.customerPhoneNumber,
                            deviceGuid: this.deviceGuid,
                            codeDeliveryMethod: TwoFactorCodeDeliveryMethod.call
                        }
                    case TwoFactorCodeDeliveryMethod.email:
                        return {
                            phoneNumber: this.customerPhoneNumber,
                            email: this.customerEmail ?? appSessionStorage.getItem(localStorageKey.displayEmail),
                            deviceGuid: this.deviceGuid,
                            codeDeliveryMethod: TwoFactorCodeDeliveryMethod.email
                        }
                    default:
                        logger.fatal(`Unexpected deliveryMethod: ${deliveryMethod}`)
                }
            },
            clickUnlockUsingBiometrics: async function () {
                this.$logEvent('click_aven_my_login_unlock_using_biometrics')
                await this.unlockUsingBiometrics()
            },
            // ==== invoked by native code ====
            unlockUsingBiometrics: async function () {
                await sendMessageToNativeApp(NATIVE_MESSAGES.BIOMETRICS, `callBack=onBiometricsComplete`)
            },
            /*
            handles successful biometrics login and error cases from native side.
            biometricsResult {
                passcode?: string
                errorCode: string
            }
             */
            // ==== invoked by native code ====
            onBiometricsComplete: async function (jsonString) {
                logger.info(`onBiometricsComplete: ${jsonString}`)
                const biometricsResult = JSON.parse(jsonString)
                if (biometricsResult.errorCode && biometricsResult.errorCode !== BiometricsError.cancelledByUser) {
                    this.$logEvent('event_aven_my_login_unlock_using_biometrics_failure')
                    logger.info(`onBiometricsComplete error: ${biometricsResult.errorCode}`)
                    useDeviceInfoStore().disableBiometrics()
                    this.applyGeneralErrorOnPhoneForm(i18n.t("global.errors.biometricsGenericError"))
                    return
                }

                if (!biometricsResult.errorCode) {
                    this.$logEvent('event_aven_my_login_unlock_using_biometrics_success')
                    logger.info(`onBiometricsComplete successful`)
                    this.password = biometricsResult.passcode
                    this.inflateFromNativeBiometricsResult(biometricsResult)
                    await this.showProductSelectionOrLogin()
                } else {
                    logger.info(`onBiometricsComplete cancelled by user`)
                }
            },
            showProductSelectionOrLogin: async function () {
                if (this.password === '') {
                    return
                }
                try {
                    this.submitting = true
                    logger.info(`Login.vue showProductSelectionOrLogin`)
                    this.productInfoResponse = await this.authorizePasswordAndGetProductInfo(this.password)
                    if (this.productInfoResponse.productInfoDetails.length === 1) {
                        logger.info(`Login.vue showProductSelectionOrLogin only one product`)
                        await this.tryLoginOnSuccessfulBiometrics()
                    } else {
                        logger.info(`Login.vue showProductSelectionOrLogin multiple products`)
                        this.showProductSelection = true
                        this.submitting = false
                    }
                } catch (error) {
                    await this.applyPasswordError(error)
                } finally {
                    this.submitting = false
                }
            },

            // This function performs the actual login action
            tryLoginOnSuccessfulBiometrics: async function () {
                try {
                    await this.loginOnSuccessfulBiometrics()
                } catch (error) {
                    await this.applyPasswordError(error)
                }
            },
            applyPasswordError: async function (error) {
                if (error instanceof AppBlockedError) {
                    logger.info(`Login.vue tryLoginOnSuccessfulBiometrics AppBlockedError`)
                    await this.presentAppBlockedView(error.blockEvent)
                } else if (error.response?.status === StatusCodes.BAD_REQUEST) {
                    logger.fatal(`Login.vue tryLoginOnSuccessfulBiometrics ${StatusCodes.BAD_REQUEST}`, error)
                    useDeviceInfoStore().disableBiometrics()
                    await sendMessageToNativeApp(NATIVE_MESSAGES.DISABLE_BIOMETRICS)
                } else if (error.response?.status === StatusCodes.UNAUTHORIZED) {
                    logger.info(`Login.vue tryLoginOnSuccessfulBiometrics ${StatusCodes.UNAUTHORIZED}`)

                    if (error.response.data.error === PasswordLoginErrors.INCORRECT_PASSWORD_ERROR) {
                        this.applyGeneralErrorOnPhoneForm(i18n.t("pages.passwordLogin.error.incorrectPassword"))
                        await sendMessageToNativeApp(NATIVE_MESSAGES.DISABLE_BIOMETRICS)
                        useDeviceInfoStore().disableBiometrics()
                    } else {
                        logger.fatal(`Login.vue tryLoginOnSuccessfulBiometrics ${StatusCodes.UNAUTHORIZED} for reason other than ${PasswordLoginErrors.INCORRECT_PASSWORD_ERROR}: ${error?.response?.data?.error}`, error)
                        appSessionStorage.clear()
                        useSessionStore().$reset()
                        await this.$router.replace('/login')
                    }
                } else if (error.response?.status === StatusCodes.NOT_FOUND) {
                    logger.info(`Login.vue tryLoginOnSuccessfulBiometrics ${StatusCodes.NOT_FOUND}`)
                    this.applyGeneralErrorOnPhoneForm(i18n.t("pages.passwordLogin.error.accountNotFound"))
                } else if (error.name === 'NavigationDuplicated') {
                    // Todo These errors are benign; the router actually does route the cardholder to the appropriate
                    //  location ... if we have time at some point we should figure out why they are thrown and fix
                    //  that root cause.
                    logger.info(`Login.vue tryLoginOnSuccessfulBiometrics NavigationDuplicated error: ${inspect(error)}`)
                    this.applyGeneralErrorOnPhoneForm(i18n.t("global.errors.generic"))
                } else {
                    logger.fatal(`Login.vue tryLoginOnSuccessfulBiometrics ${inspect(error)}`, error)
                    this.applyGeneralErrorOnPhoneForm(i18n.t("global.errors.generic"))
                }
            },
            // This function wraps the login action and handles any errors that occur
            loginOnSuccessfulBiometrics: async function () {
                try {
                    this.submitting = true
                    logger.info('logging in on successful biometrics')
                    await this.loginWithPassword(this.password)
                    logger.info('successfully logged in after successful biometrics')
                    await this.getAccountInfoAndNavigate()
                } catch (error) {
                    this.password = ''
                    this.showProductSelection = false
                    throw error // rethrow the error to handle it in the wrapper
                } finally {
                    this.submitting = false
                }
            },
            sendSupportText() {
                this.$logEvent('click_aven_my_login_help_send_support_text')
                logger.info(`Login.vue send support text: sms:${contact_info.sms}`)
                window.location.href = `sms:${contact_info.sms}`
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appBase';
    @import 'node_modules/aven_shared/src/styles/components/appHeader.scss';

    .form-group {
        margin-bottom: 0;
    }

    .other-options {
        font-size: $font-size-base;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $input-height;

        button {
            background: none;
            border: none;
            font-weight: $font-weight-bold;
            color: $black;

            &:first-child {
                margin-right: 4px;
            }

            &:last-child {
                margin-left: 4px;
            }
        }
    }
</style>
