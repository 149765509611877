<template>
    <div>
        <card-block v-if="!userOptedOutSweepstakes">
            <div class="d-flex flex-column row-gap bg-white">
                <div>
                    <h6 class="fw-bold mb-2">
                        Pay My Mortgage Sweepstakes
                    </h6>
                </div>
                <div>
                    {{ cardDescription }}
                </div>

                <div
                    v-if="!showWinner"
                    class="mt-2 bg-gray-100 p-2"
                >
                    You are automatically enrolled in this sweepstakes.
                </div>

                <p class="mt-3 text-muted small text-center mb-3">
                    See full <a
                        href="https://www.my.aven.com/docs/PayMyMortgageSweepstakesTermsAndConditions.pdf"
                        target="_blank"
                        class="text-black"
                    > Contest Rules</a> for for terms and conditions of
                    the Pay My Mortgage Sweepstakes. Aven will pay the winner(s) the value of their monthly mortgage payment up to $2000 as a statement credit. To opt-out,
                    <a
                        @click.once="handleClick"
                        @keydown.enter.once="handleClick"
                        class="text-black"
                        style="cursor: pointer"
                        data-test="opt-out-link"
                    >click here</a>.
                </p>
            </div>
        </card-block>
        <card-block v-if="userOptedOutSweepstakes && allowReOptIn">
            <h5>Pay My Mortgage Sweepstakes</h5>
            <p class="text-muted small text-center mb-3">
                You have opted out of the Pay My Mortgage Sweepstakes. To opt back in,
                <a
                    @click.once="handleClick"
                    @keydown.enter.once="handleClick"
                    class="text-black"
                    style="cursor: pointer"
                    data-test="opt-in-link"
                >click here</a>.
            </p>
        </card-block>
    </div>
</template>

<script lang="ts">
    import _ from 'lodash'
    import CardBlock from '@/components/CardBlock.vue'
    import { useOverviewStore } from '@/store/overviewStore'
    import { updateOptOutSweepstakes } from '@/services/api'
    import { WebViewMessageEventName } from 'aven_types'
    import { getAvenMyPdf } from '@/services/avenAppApi'
    import { logger } from '@/utils/logger'
    import { isSafari } from '@/utils/parseUserAgents'

    export default {
        name: 'SweepstakesCard',

        components: { 'card-block': CardBlock },
        async mounted() {
            await useOverviewStore().refreshAvenMySweepstakesInfoIfNecessary()
            this.$logEvent('aven_my_sweepstakes_view')
        },
        computed: {
            userOptedOutSweepstakes() {
                return useOverviewStore().userOptedOutSweepstakes
            },
            cardDescription() {
                return this.showWinner
                    ? `Congrats to ${this.winnerName ?? 'our lucky user'} who won the last $2000 prize! See you again next week!`
                    : 'Contest runs every Friday and statement credit will be issued within 14 days! The winner will be notified by email within 24 hours, so watch your inbox.'
            },
            showWinner() {
                if (_.isEmpty(this.winnerName)) return false

                // Get current UTC time
                const now = new Date()

                // Get current date components in Pacific Time
                const pacificNow = new Date(now.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))

                // Set start time to 3:00 PM PT
                const startTime = new Date(pacificNow.getFullYear(), pacificNow.getMonth(), pacificNow.getDate(), 15, 0, 0, 0)

                // Set end time to 11:59:59 PM PT
                const endTime = new Date(pacificNow.getFullYear(), pacificNow.getMonth(), pacificNow.getDate(), 23, 59, 59, 999)

                const dayOfWeek = now.getDay()
                if (dayOfWeek === 5) {
                    // Friday
                    return now.getTime() >= startTime.getTime()
                } else if (dayOfWeek === 6) {
                    // Saturday
                    return true
                } else if (dayOfWeek === 0) {
                    // Sunday
                    return now.getTime() <= endTime.getTime()
                } else {
                    return false // If it's not Friday, Saturday, or Sunday, return false
                }
            },
            winnerName() {
                return useOverviewStore().lastWinnerDisplayName
            },
            allowReOptIn() {
                return useOverviewStore().isInternalEmployee || process.env.NODE_ENV === 'development'
            },
        },
        methods: {
            async handleClick() {
                await updateOptOutSweepstakes(!this.userOptedOutSweepstakes)
                await useOverviewStore().refreshAvenMySweepstakesInfoIfNecessary(true)
            },
            // below does not seem to work in mobile device any more, needs more investigation
            showTnC() {
                const url = '/docs/PayMyMortgageSweepstakesTermsAndConditions.pdf'
                const msg = {
                    eventName: WebViewMessageEventName.OPEN_EXTERNAL_WEB_VIEW,
                    payload: {
                        url: `https://www.my.aven.com${url}`,
                        title: 'Pay My Mortgage Sweepstakes Terms and Conditions',
                    },
                }
                if ((window as any).ReactNativeWebView) {
                    (window as any).ReactNativeWebView.postMessage(JSON.stringify(msg))
                } else {
                    window.open(url, '_blank')
                }
            },

            async showTnCLoadingPdf() {
                const url = '/docs/PayMyMortgageSweepstakesTermsAndConditions.pdf'
                let response
                try {
                    logger.info(`showing  ${url}`)
                    response = await getAvenMyPdf(url)
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {
                        type: 'application/pdf',
                    })
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file)
                    if (isSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                } catch (error) {
                    logger.error('Error loading pdf:')
                    logger.error(error)
                }
            },
        },
    }
</script>

<style></style>
