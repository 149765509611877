<template>
    <div>
        <ValidationProvider
            :mode="validationMode"
            v-slot="{ valid, errors }"
            :vid="name"
            :rules="validationRules"
        >
            <div class="input form-floating">
                <input
                    type="tel"
                    class="form-control"
                    :id="name"
                    v-mask="'##/##/####'"
                    :class="{ 'is-invalid': errors[0], 'is-valid': valid }"
                    :name="name"
                    :placeholder="dynamicPlaceholder"
                    :data-testid="dataTestid"
                    inputmode="decimal"
                    autocomplete="date"
                    v-model="value"
                    @input="onInput($event.target)"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                >
                <label
                    v-if="dynamicPlaceholder"
                    :for="name"
                >{{ dynamicPlaceholder }}</label>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'

    /**
     * Component for inputting dates with formatting and validation
     */
    export default {
        name: 'FormFieldDate',
        mixins: [formInputMixin],
        props: {
            validationRules: { type: String, default: 'required|dateRule' },
            options: {
                type: Object,
                default: function () {
                    return { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] }
                },
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
    }
</script>
