<template>
    <div class="tab-activity">
        <app-header
            :title="$t('pages.activity.title')"
            class="mobile-header"
        />
        <div class="container position-relative px-0 px-md-3">
            <div
                class="account-overview mb-2"
                v-show="!accountIsClosed"
            >
                <div class="card card-shadow rounded-3">
                    <div
                        class="card-background"
                        data-testid="activity-balance"
                    >
                        <h3 class="text-responsive">
                            {{ formatAmount(this.currentBalance) }}
                        </h3>
                        <p
                            class="section-header"
                            v-html="cardSubtitle"
                        />
                        <img
                            src="@/assets/images/pages/activity/VisaWhite.svg"
                            alt="Visa"
                        >
                        <p class="card-signature mb-0">
                            {{ nameToDisplay }}
                        </p>
                    </div>
                    <div
                        v-if="hasFullLineSizeContingencies && allowedToRequestFullLineSize"
                        class="d-flex justify-content-center align-items-center my-1 card-shadow"
                    >
                        <b>
                            <list-row
                                :show-arrow="true"
                                :title="requestFullCreditLimitTitle"
                                :href-link="RoutePaths.REQUEST_FULL_CREDIT_LIMIT"
                            />
                        </b>
                    </div>
                </div>
                <common-actions class="pt-2 px-md-3" />
            </div>

            <card-block
                v-if="canShowCreditLimitIncreaseCard"
                :title="$t('pages.activity.requestHigherCreditLimitTitle')"
                class="mb-2"
            >
                <p>{{ $t('pages.activity.requestHigherCreditLimitDescription') }}</p>
                <base-button @click="clickCreditLimitIncreaseButton">
                    {{ $t('pages.activity.requestHigherCreditLimitButton') }}
                </base-button>
            </card-block>

            <sweepstakes-card class="mb-2" />

            <estimated-savings-card class="mb-2" />

            <pay-it-forward-card
                @open-pif-info-dialog="openPifInfoModal"
                v-if="showPifInfoCard"
                class="mb-2"
                :screen-name="PifScreen.ACTIVITY"
            />

            <card-block
                :title="minimumDue > 0 ? $t('pages.WhatIsDue.whatIsDue') : $t('pages.WhatIsDue.nothingDue')"
                class="mb-2"
            >
                <what-is-due />
            </card-block>

            <creator-challenge-card
                v-if="shouldShowCreatorChallengeCard"
                class="mb-2"
            />

            <!--
            I check showBalanceTransferFeature both here and within the component.
            This is intentionally over-prescriptive to be safe against future changes, and so future engineers can clearly see the pattern.
            If you want to remove one of the checks, remove this superfluous one and keep the check within the component.
            -->
            <balance-transfer-card v-if="shouldShowBalanceTransferCard" />

            <!-- Upcoming AutoPay date and amount -->
            <card-block
                :title="$t('pages.activity.Upcoming')"
                v-if="(autoPaySetting.isEnabled && nextAutoPayAmount > 0) || scheduledPayments.length > 0"
                class="mb-2"
            >
                <transaction-row
                    v-if="autoPaySetting.isEnabled && nextAutoPayAmount > 0"
                    :title="$t('pages.activity.autoPay')"
                    :date="formatDateUtc(autopayNextPaymentDisplayDate)"
                    highlight-color="text-success"
                    :value="autoPayAmount"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.AUTO_PAY)"
                />
                <transaction-row
                    v-for="[idx, value] of scheduledPayments.entries()"
                    :key="idx"
                    :title="$t('pages.activity.scheduledPayment')"
                    :date="dayjs(value.scheduledPaymentDate).format(DISPLAY_DATE_FORMAT)"
                    :data-testid="`scheduled-payment-option-${idx + 1}`"
                    highlight-color="text-success"
                    :value="prettyPrintDollarAmount(value.paymentAmount)"
                    :show-arrow="true"
                    @onClick="
                        $router.push({
                            name: RouteNames.SINGLE_SCHEDULED_PAYMENT,
                            params: {
                                scheduledOneTimePaymentId: scheduledPayments[idx].id,
                                scheduledPaymentAmount: scheduledPayments[idx].paymentAmount,
                                scheduledPaymentDate: scheduledPayments[idx].scheduledPaymentDate,
                                last4AccountNumber: scheduledPayments[idx].last4AccountNumber,
                            },
                        })
                    "
                />
            </card-block>

            <mortgage-cashback-v2-card v-if="showMortgageCashbackV2Card" />

            <mortgage-payment-rewards-card v-if="showMortgagePaymentRewards" />

            <refi-survey-card v-if="isInternalEmployee" />

            <transaction-list />

            <div
                class="small my-1 mx-2"
                v-if="showEstimatedSavingsFootnote"
                v-html="estimatedSavingsFootnoteText"
            />
        </div>
        <modal
            :show="showPifInfoModal"
            :title="$t('pages.payItForwardContactList.infoModalTitle')"
            @close="closePifInfoModal"
        >
            <p
                class="text-center mb-3"
                v-html="$t(`components.pifFooter.${pifRewardType}.content`)"
            />
            <base-button @click="closePifInfoModal">
                {{ $t('pages.payItForwardContactList.infoModalClose') }}
            </base-button>
        </modal>
        <app-store-review-modal
            :visibility="showAppReviewModal"
            @close="showAppReviewModal = false"
        />
    </div>
</template>
<script>
    import Header from '../components/AppHeader'
    import generic from '@/utils/generic'
    import { logger } from '@/utils/logger'
    import { DISPLAY_DATE_FORMAT, NYC_TIMEZONE, TransactionType } from '@/data/constants'
    import dayjs from 'dayjs'
    import PayItForwardCard from '@/components/PayItForwardCard'
    import CommonActions from '@/components/CommonActions'
    import CardBlock from '@/components/CardBlock'
    import TransactionRow from '@/components/TransactionRow'
    import WhatIsDue from '@/components/WhatIsDue'
    import { i18n } from '@/utils/i18n'
    import { useOverviewStore } from '@/store/overviewStore'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import { usePaymentsStore } from '@/store/paymentsStore'
    import { useCashOutAndBalanceTransferStore } from '@/store/cashOutAndBalanceTransferStore'
    import BaseButton from '@/components/base/BaseButton.vue'
    import Modal from '@/components/Modal.vue'
    import BalanceTransferCard from '@/components/BalanceTransferCard.vue'
    import ListRow from '@/components/ListRow.vue'
    import { toFormattedAprStripTrailingZerosAfterDecimalPoint, toFormattedUSDKs } from '@/mixins/format'
    import { PifScreen } from '@/utils/pifManager'
    import RefiSurveyCard from '@/components/RefiSurveyCard.vue'
    import SweepstakesCard from '@/components/SweepstakesCard.vue'
    import TransactionList from '@/components/TransactionList.vue'
    import { DebtProductAndMetaDataSet } from '@/services/avenAppApi'
    import CreatorChallengeCard from '@/components/CreatorChallengeCard.vue'
    import { CreatorChallengeExperimentBucket, useExperimentStore } from '@/store/experimentStore'
    import MortgagePaymentRewardsCard from '@/components/MortgagePaymentRewardsCard.vue'
    import EstimatedSavingsCard from '@/components/EstimatedSavingsCard.vue'
    import { useCreditCardMarketDataStore } from '@/store/creditCardMarketDataStore'
    import { useEstimatedSavingsStore } from '@/store/estimatedSavingsStore'
    import AppStoreReviewModal from '@/views/AppStoreReviewModal.vue'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import MortgageCashbackV2Card from '@/components/MortgageCashbackV2Card.vue'
    import { useCustomerInfoStore } from '@/store/customerInfoStore'
    import capitalize from 'lodash/capitalize'

    export default {
        name: 'Activity',
        components: {
            MortgageCashbackV2Card,
            AppStoreReviewModal,
            MortgagePaymentRewardsCard,
            EstimatedSavingsCard,
            CreatorChallengeCard,
            TransactionList,
            RefiSurveyCard,
            ListRow,
            BalanceTransferCard,
            Modal,
            BaseButton,
            WhatIsDue,
            CardBlock,
            PayItForwardCard,
            'app-header': Header,
            CommonActions,
            TransactionRow,
            SweepstakesCard,
        },
        data() {
            return {
                showPifInfoModal: false,
                TransactionType,
                RoutePaths,
                DISPLAY_DATE_FORMAT,
                prettyPrintDollarAmount: generic.prettyPrintDollarAmount,
                showAppReviewModal: false,
            }
        },
        computed: {
            nameToDisplay() {
                let name = `${capitalize(useCustomerInfoStore().firstName)} ${capitalize(useCustomerInfoStore().lastName)}`
                if (name.length > 30) {
                    // Name is too long, we will grab the first name only
                    name = `${capitalize(useCustomerInfoStore().firstName)}`
                }
                return name
            },
            RouteNames() {
                return RouteNames
            },
            PifScreen() {
                return PifScreen
            },
            dayjs() {
                return dayjs
            },
            existingBalanceTransfers() {
                return useCashOutAndBalanceTransferStore().existingBalanceTransfers
            },
            existingCashOutTransfers() {
                return useCashOutAndBalanceTransferStore().existingCashOutTransfers
            },
            accountIsClosed() {
                return useOverviewStore().accountIsClosed
            },
            fastFundingLineSizeIfApproved() {
                return useOverviewStore().fastFunding.lineSizeIfApproved
            },
            fastFundingDecisionETA() {
                return useOverviewStore().fastFunding.decisionETA
            },
            paymentDueDate() {
                return useOverviewStore().paymentDueDate
            },
            autopayNextPaymentDisplayDate() {
                return useOverviewStore().autopayNextPaymentDisplayDate
            },
            minimumDue() {
                return useOverviewStore().minimumDue
            },
            nextStatementDate() {
                return useOverviewStore().nextStatementDate
            },
            currentBalance() {
                return useOverviewStore().currentBalance
            },
            autoPaySetting() {
                return useOverviewStore().autoPaySetting
            },
            scheduledPayments() {
                return useOverviewStore().scheduledPayments
            },
            rescissionInfo() {
                return useOverviewStore().rescissionInfo
            },
            statementRemainingBalance() {
                return useOverviewStore().statementRemainingBalance
            },
            availableCredit() {
                return useOverviewStore().availableCredit
            },
            apr() {
                return useOverviewStore().apr
            },
            nextAutoPayAmount() {
                return useOverviewStore().nextAutoPayAmount
            },
            cardRequiresActivation() {
                return useOverviewStore().cardRequiresActivation
            },
            isHomeProduct() {
                return useOverviewStore().isHomeProduct
            },
            didAcceptMethodFiTos() {
                return useOverviewStore().didAcceptMethodFiTos
            },
            balanceTransferBlockReason() {
                return useOverviewStore().balanceTransferBlockReason
            },
            isAccountBTCOEnabled() {
                return useOverviewStore().isAccountBTCOEnabled
            },
            isAccountCOEnabled() {
                return useOverviewStore().isAccountCOEnabled
            },
            isAccountBTEnabled() {
                return useOverviewStore().isAccountBTEnabled
            },
            isAccountInstantBTEnabled() {
                return useOverviewStore().isAccountInstantBTEnabled
            },
            showBalanceTransferFeature() {
                return useOverviewStore().showBalanceTransferFeature
            },
            canShowInstantBalanceTransferOptionsCard() {
                return useOverviewStore().canShowInstantBalanceTransferOptionsCard
            },
            shouldShowBalanceTransferCard() {
                const canShowInstantBtOptionsCard = this.canShowInstantBalanceTransferOptionsCard
                if (!canShowInstantBtOptionsCard.canShow) {
                    logger.info(`Cardholder shouldn't see instant balance transfer options card: ${JSON.stringify(canShowInstantBtOptionsCard)}. Hiding BT card in Activity`)
                    return false
                }
                logger.info(`Cardholder should see instant balance transfer options card in Activity: ${JSON.stringify(canShowInstantBtOptionsCard)}`)
                return true
            },
            shouldShowCreatorChallengeCard() {
                return useExperimentStore().getCreatorChallengeExperimentBucket === CreatorChallengeExperimentBucket.variant
            },
            autoPayAmount: function () {
                return generic.prettyPrintDollarAmount(Math.abs(this.nextAutoPayAmount).toString())
            },
            showPifInfoCard: function () {
                return !this.accountIsClosed && this.isHomeProduct
            },
            cardSubtitle: function () {
                return i18n.t('pages.activity.Available', {
                    openToBuyAmount: this.formatAmount(this.availableCredit),
                })
            },
            hasFullLineSizeContingencies() {
                return useOverviewStore().fullLineSizeContingencies.length > 0
            },
            allowedToRequestFullLineSize() {
                return useOverviewStore().allowedToRequestFullLineSize
            },
            fullLineSize() {
                return useOverviewStore().fullLineSize
            },
            requestFullCreditLimitTitle() {
                return `Unlock ${toFormattedUSDKs(this.fullLineSize)} Limit`
            },
            isInternalEmployee() {
                return useOverviewStore().isInternalEmployee
            },
            showMortgagePaymentRewards() {
                return useOverviewStore().mortgagePaymentRewardsEnabled && !this.showMortgageCashbackV2Card
            },
            showMortgageCashbackV2Card() {
                return useOverviewStore().mortgagePaymentRewardsV2Enabled
            },
            creditLimitIncreaseApplicationUrl() {
                return useOverviewStore().creditLimitIncreaseApplicationUrl
            },
            canShowCreditLimitIncreaseCard() {
                return !!this.creditLimitIncreaseApplicationUrl
            },
            showEstimatedSavingsFootnote() {
                return useEstimatedSavingsStore().hasSavingsWorthShowing
            },
            estimatedSavingsFootnoteText() {
                const creditCardMarketDataStore = useCreditCardMarketDataStore()
                return this.$t('components.estimatedSavingsCard.footnote', {
                    articleName: creditCardMarketDataStore.sourceArticleName,
                    linkUrl: creditCardMarketDataStore.sourceWebsiteUrl,
                    linkText: creditCardMarketDataStore.sourceWebsiteLinkText,
                    dateUpdated: creditCardMarketDataStore.dateUpdatedString,
                    formattedGoodCreditApr: toFormattedAprStripTrailingZerosAfterDecimalPoint(creditCardMarketDataStore.avgGoodCreditApr),
                })
            },
            pifRewardType: function () {
                return useOverviewStore().pifRewardType
            },
        },
        mounted: function () {
            logger.info(`------ Activity Mounted ------`)
            this.$logEvent('view_activity', {
                balanceTransferBlockReason: this.balanceTransferBlockReason,
                didAcceptMethodFiTos: this.didAcceptMethodFiTos,
                isAccountBTCOEnabled: this.isAccountBTCOEnabled,
                isAccountCOEnabled: this.isAccountCOEnabled,
                isAccountBTEnabled: this.isAccountBTEnabled,
                isAccountInstantBTEnabled: this.isAccountInstantBTEnabled,
                showBalanceTransferFeature: this.showBalanceTransferFeature,
                hasFullLineSizeContingencies: this.hasFullLineSizeContingencies,
                allowedToRequestFullLineSize: this.allowedToRequestFullLineSize,
                canShowInstantBalanceTransferOptionsCard: this.canShowInstantBalanceTransferOptionsCard.canShow,
            })
            window.refreshData = this.refreshData

            // Showing the app review modal for a user who 1. has the recent native version 2. has not seen the modal before (handled in native) 3. has activated their card
            this.showAppReviewModal = useDeviceInfoStore().canAskForAppReview && !this.cardRequiresActivation
        },
        methods: {
            formatAmount(amount) {
                return generic.prettyPrintDollarAmount(amount)
            },
            formatDateUtc(date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
            },
            refreshData: async function () {
                logger.info('Refresh transaction list')
                await usePaymentsStore().refreshAutoPayDetails()
                await useOverviewStore().updateAccountOverview({
                    debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all,
                })
            },
            openPifInfoModal: function () {
                this.showPifInfoModal = true
            },
            closePifInfoModal: function () {
                this.showPifInfoModal = false
            },
            clickCreditLimitIncreaseButton: async function () {
                this.$logEvent('click_credit_limit_increase_card')
                if (this.creditLimitIncreaseApplicationUrl) {
                    window.open(this.creditLimitIncreaseApplicationUrl, '_blank')
                } else {
                    logger.error('Credit limit increase application url is not available')
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .tab-activity {
        background-color: $gray-100;
    }

    .card-signature {
        text-transform: capitalize;
        font-family: Holimount;
        font-size: MIN(8.9vw, 40px);
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 3.25rem */
        position: absolute;
        bottom: 12px;
        left: 24px;
    }

    .account-overview {
        background-color: $white;
        border-radius: 0;
        border: none;
        padding: 24px;

        @include media-breakpoint-up(md) {
            border-radius: $border-radius-lg;
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .card-background {
            background-color: $black;
            color: $white;
            border-radius: $border-radius-lg;
            height: 206px;
            width: 100%;
            padding: 24px;
            position: relative;

            @include media-breakpoint-up(md) {
                padding: 24px 32px;
                height: 260px;
            }
        }

        img {
            position: absolute;
            bottom: 24px;
            right: 24px;
            width: 64px;

            @include media-breakpoint-up(md) {
                width: 80px;
            }
        }
    }

    .current-balance {
        height: 292px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
