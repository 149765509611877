import router, { getRootAndDeeplinkFromPath } from '@/routes/router'
import { i18n } from '@/utils/i18n'
import { toFormattedUSDNoCents } from '@/mixins/format'
import { logger } from '@/utils/logger'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { AccountActivationRequirementStatus, AvenCardActivationStatus, AvenCardProductType, PifRewardType } from '@/store/overviewStore.types'
import { useOverviewStore } from '@/store/overviewStore'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { RouteNames, RoutePaths } from '@/routes/router.types'
import { javascriptNativeInterop, PermissionStatus } from '@/services/javascriptNativeInterop'
import { usePersonalFinancialLiabilityStore } from '@/store/personalFinancialLiabilityStore'
import dayjs from 'dayjs'

// We've had trouble with post-login routes causing infinite loops by recursively re-routing
// through postLoginNavigation(), so we want to limit that as much as possible. However, there
// are some routes that have a legitimate cause to call the method:
// - LOGIN/PASSWORD_LOGIN/SET_PASSWORD: These are the login routes, so it makes sens that after they log the
//   cardholder in, they would call postLoginNavigation.
// - PUSH_NOTIFICATION_PERMISSION: Since push notification communication is so critical, we need to make sure
//   to get permission from cardholders that haven't provided it. We don't want any other route to hijack
//   that request, so we route here first, then back through postLoginNavigation to see what's next.
// - CARD_ACTIVATION/FAST_FUNDING/CARD_ARRIVAL_CHECK: Other routes we might navigate to post-login rely on
//   the card being activated. These routes perform card activation, so it makes sense we'd execute the
//   actions at these routes, then move forward to the next route in postLoginNavigation.
const routePathsAllowedToCallPostLoginNavigation: RoutePaths[] = [
    RoutePaths.LOGIN,
    RoutePaths.PASSWORD_LOGIN,
    RoutePaths.SET_PASSWORD,
    RoutePaths.PUSH_NOTIFICATION_PERMISSION,
    RoutePaths.CARD_ACTIVATION,
    RoutePaths.FAST_FUNDING,
    RoutePaths.CARD_ARRIVAL_CHECK,
    RoutePaths.CASH_OUT_UPFRONT_V2_ACTIVATION,
    RoutePaths.MAILER_CODE_ACTIVATION,
]

export const postLoginNavigation = async (skipCardActivation: boolean = false) => {
    window.logEvent('event_start_post_login_navigation')

    // If the current route is not allowed to call this method, we'll log an exception and thus trigger
    // a PagerDuty alert. We won't block execution of this method, though, because we want the app to
    // continue working. This will just be an alert to fix the violation.
    if (!routePathsAllowedToCallPostLoginNavigation.includes(window.location.pathname as RoutePaths)) {
        logger.fatal(`Route ${window.location.pathname} is not allowed to call postLoginNavigation(). It should call navigation.returnToRoot() instead`)
    }
    // Post login, we always want to ask for push permission if we can. We use push extensively
    // for cardholder communication, so it's very important that other navigation flows don't
    // short circuit our ability to do this.
    const shouldNavigateToPushNotificationPermission = await checkIfShouldNavigateToPushNotificationPermission()
    if (shouldNavigateToPushNotificationPermission) {
        return await router.replace({ name: RouteNames.PUSH_NOTIFICATION_PERMISSION })
    }

    const overviewStore = useOverviewStore()
    // Special handling when we have a non-closed non-blocked account that requires activation
    const shouldShowActivationFlow = !skipCardActivation && !overviewStore.accountIsClosed && overviewStore.cardRequiresActivation && !overviewStore.isBlockedByContingencies
    logger.log(
        `skipCardActivation: ${skipCardActivation}, accountIsClosed: ${overviewStore.accountIsClosed}, cardRequiresActivation: ${overviewStore.cardRequiresActivation}, isBlockedByContingencies: ${overviewStore.isBlockedByContingencies}`
    )
    if (shouldShowActivationFlow) {
        logger.log(`postLoginNavigation, triggering special logic because account card requires activation`)

        // The Cash Out Upfront UI is tucked into CardActivation.vue, so if the cardholder's state
        // is such that they should see the Cash Out Upfront flow, we'll route to /cardActivation.
        // Todo There's a bit of hidden logic here that makes this flow a little harder to understand.
        //  When a "cash out upfront" cardholder first arrives at the app w/ out their card activated,
        //  we'll bypass /cardActivation and instead go to /cardArrivalCheck below. That route asks the
        //  cardholder whether they have their card yet, and if yes, navigates to /cardActivation where
        //  the flow to validate the last 4 digits and move through "cash out upfront" is self-contained.
        //  We can improve understandability of this flow by moving "cash out upfront" into its own route
        //  rather than sharing one with other card activation functions.
        const shouldShowCashOutUpfrontV1 =
            overviewStore.isAccountCashOutUpfrontV1Enabled &&
            [AvenCardActivationStatus.last4DigitValidated, AvenCardActivationStatus.cashOutFullDrawInProgress, AvenCardActivationStatus.cashOutFullDrawError].includes(
                overviewStore.cardActivationStatus
            ) &&
            overviewStore.cashOutUpfrontTransferAmountWithFee > 0
        if (shouldShowCashOutUpfrontV1) {
            logger.info(`Cash Out Upfront is enabled and activation is still ongoing (status=${overviewStore.cardActivationStatus}), triggering special Card Activation flow`)
            return await router.push({
                name: RouteNames.CARD_ACTIVATION,
            })
        }

        if (
            overviewStore.isAccountCashOutUpfrontV2Enabled &&
            [AvenCardActivationStatus.pending, AvenCardActivationStatus.cashOutFullDrawDrafted].includes(overviewStore.cardActivationStatus) &&
            overviewStore.cashOutUpfrontTransferAmountWithFee > 0
        ) {
            logger.info(`Cash Out Upfront V2 is enabled, routing to CASH_OUT_UPFRONT_V2_ACTIVATION and letting that component handle further routing`)
            return await router.push({
                name: RouteNames.CASH_OUT_UPFRONT_V2_ACTIVATION,
            })
        } else {
            // TODO: this is a bit too fragile. We want to hide nav controls during CO for these folks, but only during
            // the initial signup. This flag is only set in CASH_OUT_UPFRONT_V2_ACTIVATION upon starting the CO flow and unset upon
            // the completion of a draft CashOut. This check isn't technically necessary, but better safe than sorry...
            appSessionStorage.removeItem(localStorageKey.preActivationCashOutFlow)
        }

        const isActivationRequirementInProgress = overviewStore.activationRequirementStatus === AccountActivationRequirementStatus.inProgress
        // We only show the card arrival check flow once per app open. If it's already been shown, we
        // don't need to show it again; there's a banner on the main screen that allows for activation.
        const hasShownCardArrivalCheck = useGlobalUiStore().hasShownCardArrivalCheck
        // Only show card arrival check if we can reasonably expect it to be delivered, i.e. 5 days after shipping
        // Arbitrary value used, subject to change. Can improve with tracking data in future.
        const optimisticCardArrivalDate = dayjs(useOverviewStore().cardShippingDate).add(5, 'days').toDate()
        const hasCardOptimisticallyArrived = !useOverviewStore().cardShippingDate || dayjs().isAfter(optimisticCardArrivalDate)
        const shouldShowCardArrivalCheck =
            !hasShownCardArrivalCheck && hasCardOptimisticallyArrived && overviewStore.cardActivationStatus !== AvenCardActivationStatus.last4DigitValidated && !isActivationRequirementInProgress
        if (shouldShowCardArrivalCheck) {
            logger.log(`CardArrivalCheck screen triggered b/c account is not closed AND card does required activation AND it is after optimisticCardArrivalDate: ${optimisticCardArrivalDate}`)
            return await router.push({
                name: RouteNames.CARD_ARRIVAL_CHECK,
            })
        }

        // Only show mailer code check flow once per app open.
        const hasShownMailerCodeArrivalCheck = useGlobalUiStore().hasShownMailerCodeArrivalCheck
        const shouldShowMailerCodeArrivalCheck =
            !hasShownMailerCodeArrivalCheck && overviewStore.isMailerActivationEnabled && overviewStore.cardActivationStatus === AvenCardActivationStatus.pending && !isActivationRequirementInProgress
        if (shouldShowMailerCodeArrivalCheck) {
            // route to the Mailer Activation flow only when the feature is enabled and still in pending activation status.
            logger.info(
                `routing to Mailer Code Activation flow with isMailerActivationEnabled=${overviewStore.isMailerActivationEnabled} and cardActivationStatus=${overviewStore.cardActivationStatus}`
            )
            return await router.push({ name: RouteNames.MAILER_CODE_ACTIVATION })
        }

        // TODO: deprecate overviewStore.hasActivationRequirements
        const hasIncompleteActivationRequirementStatus =
            !!overviewStore.activationRequirementStatus &&
            ![AccountActivationRequirementStatus.complete, AccountActivationRequirementStatus.bypassed].includes(overviewStore.activationRequirementStatus)
        const isFixedTermWithActivationRequirement = overviewStore.isFixedTermUser && (overviewStore.hasActivationRequirements || hasIncompleteActivationRequirementStatus)
        if (isFixedTermWithActivationRequirement && hasCardOptimisticallyArrived) {
            logger.log(`After validating card or mailer code, has active fixed-term activation requirement, card has optimistically arrived, routing to Fixed-Term CashOut Upfront page`)
            return await router.push({ name: RouteNames.FIXED_TERM_CASH_OUT })
        }

        // We'll only show the Fast Funding flow for eligible cardholders, but before we do that, we need
        // to give the cardholder a chance to activate their card if they have it already. Then we show
        // mailer code activation if they are eligible. After those two, we'll show the Fast Funding flow
        // for eligible cardholder.
        const shouldShowFastFundingScreen =
            !overviewStore.isAccountCashOutUpfrontV1Enabled && !overviewStore.isAccountCashOutUpfrontV2Enabled && !overviewStore.isFixedTermUser && overviewStore.fastFunding.isEligible
        if (shouldShowFastFundingScreen) {
            logger.log(`Triggering fast funding page b/c account is not closed AND card requires activation AND account is fast funding eligible`)
            return await router.push({
                name: RouteNames.FAST_FUNDING,
            })
        }
    }

    if (overviewStore.isCryptoProduct) {
        await postLoginNavigationForCryptoProduct()
    } else if (overviewStore.isAutoProduct) {
        await postLoginNavigationForAutoProduct()
    } else {
        await postLoginNavigationForHomeProduct()
    }
}

export const checkIfShouldNavigateToPushNotificationPermission = async (): Promise<boolean> => {
    const deviceInfoStore = useDeviceInfoStore()
    if (deviceInfoStore.nativeCanProvideAndRequestPushPermission && !deviceInfoStore.webHasAskedForPushPermission) {
        const currentPushNotifPermission = await javascriptNativeInterop.getCurrentPushNotificationPermissions()
        if (currentPushNotifPermission === PermissionStatus.NotDetermined) {
            logger.info(`Aven My native still needs to request push notification permission. Replacing with route ${RouteNames.PUSH_NOTIFICATION_PERMISSION}`)
            return true
        } else {
            logger.info(`Aven My web already has a push permission result from native: ${currentPushNotifPermission}. Not requesting again`)
            return false
        }
    }
    logger.info(`Aven My native cannot provide push notification permission, or web has already asked for it. Not requesting again`)
    return false
}

const postLoginNavigationForCryptoProduct = async () => {
    const overviewStore = useOverviewStore()
    const globalUiStore = useGlobalUiStore()
    if (overviewStore.isCryptoProduct) {
        // TODO Crypto: Only show if you have not deposited Crypto
        globalUiStore.setCurrentRootPath(RoutePaths.ACTIVITY)
        await router.push(RoutePaths.DEPOSIT_CRYPTO)
    } else if (overviewStore.showEnrollAutoPayAndKeepAprDiscount && !overviewStore.alreadyShownEnrollInAutoPayAndKeepAprDiscount) {
        globalUiStore.setCurrentRootPath(RoutePaths.ACTIVITY)
        await router.push(RoutePaths.SETUP_AUTOPAY)
    } else {
        globalUiStore.setCurrentRootPath(RoutePaths.ACTIVITY)
        await router.push(RoutePaths.ACTIVITY)
    }
}

const postLoginNavigationForAutoProduct = async () => {
    const overviewStore = useOverviewStore()
    const globalUiStore = useGlobalUiStore()
    if (globalUiStore.deeplinkPath.length > 0) {
        logger.info(`postLoginNavigationForAutoProduct, deeplink: ${globalUiStore.deeplinkPath}`)
        window.logEvent('event_opened_deep_link', { deeplink: globalUiStore.deeplinkPath, root: globalUiStore.currentRootPath })
        const deeplinkPath = globalUiStore.deeplinkPath
        globalUiStore.updateDeeplinkPath('')
        await router.push({
            path: deeplinkPath,
            query: {
                fromDeeplink: 'true',
            },
        })
    } else if (overviewStore.showEnrollAutoPayAndKeepAprDiscount && !overviewStore.alreadyShownEnrollInAutoPayAndKeepAprDiscount) {
        logger.info(`showEnrollAutoPayAndKeepAprDiscount = ${overviewStore.showEnrollAutoPayAndKeepAprDiscount} & haven't already shown interstitial, navigating to ${RoutePaths.SETUP_AUTOPAY}`)
        await router.push(RoutePaths.SETUP_AUTOPAY)
    } else {
        logger.info(`No specific post navigation action for auto product, navigating to ${globalUiStore.currentRootPathOrDefault}`)
        await router.push(globalUiStore.currentRootPathOrDefault)
    }
}

const shouldShowInstantBalanceTransferInOnboarding = (): boolean => {
    const overviewStore = useOverviewStore()
    const inputs = {
        shouldShowInstantBtDuringOnboarding: overviewStore.shouldShowInstantBtDuringOnboarding,
        showBalanceTransferFeature: overviewStore.showBalanceTransferFeature,
        isAccountBTEnabled: overviewStore.isAccountBTEnabled,
        isAccountInstantBTEnabled: overviewStore.isAccountInstantBTEnabled,
        balanceTransferBlockReason: overviewStore.balanceTransferBlockReason,
        alreadyShownInstantBalanceTransferInOnboarding: overviewStore.alreadyShownInstantBalanceTransferInOnboarding,
    }
    const shouldShow =
        inputs.shouldShowInstantBtDuringOnboarding &&
        inputs.showBalanceTransferFeature &&
        inputs.isAccountBTEnabled &&
        inputs.isAccountInstantBTEnabled &&
        inputs.balanceTransferBlockReason === null &&
        !inputs.alreadyShownInstantBalanceTransferInOnboarding
    logger.info(`Result of shouldShowInstantBalanceTransferInOnboarding is ${shouldShow} w/ inputs ${JSON.stringify(inputs)}`)
    return shouldShow
}

const postLoginNavigationForHomeProduct = async () => {
    const overviewStore = useOverviewStore()
    const globalUiStore = useGlobalUiStore()
    if (globalUiStore.deeplinkPath.length > 0) {
        logger.info(`postLoginNavigationForHomeProduct, deeplink: ${globalUiStore.deeplinkPath}`)
        window.logEvent('event_opened_deep_link', { deeplink: globalUiStore.deeplinkPath, root: globalUiStore.currentRootPath })
        const deeplinkPath = globalUiStore.deeplinkPath
        globalUiStore.updateDeeplinkPath('')
        return await router.push({
            path: deeplinkPath,
            query: {
                fromDeeplink: 'true',
            },
        })
    }
    if (overviewStore.isFirstSessionForCustomer) {
        logger.info(`isFirstSessionForCustomer = ${overviewStore.isFirstSessionForCustomer}, navigating to ${RoutePaths.PAY_IT_FORWARD_CONTACT_LIST}`)
        return await router.push(RoutePaths.PAY_IT_FORWARD_CONTACT_LIST)
    }
    if (overviewStore.showSignOnBonusRealEstateAgentScreen) {
        logger.info(`showSignOnBonusRealEstateAgentScreen = ${overviewStore.showSignOnBonusRealEstateAgentScreen}, navigating to ${RoutePaths.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT}`)
        return await router.push(RoutePaths.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT)
    }
    if (overviewStore.payItForwardTipInfo.isEligible) {
        logger.info(`isEligibleToPerformPayItForwardTipping = ${overviewStore.payItForwardTipInfo.isEligible}, navigating to ${RoutePaths.PAY_IT_FORWARD_SEND_TIP}`)
        return await router.push(RoutePaths.PAY_IT_FORWARD_SEND_TIP)
    }
    if (overviewStore.showSimpleInterestConversionInterstitial && !overviewStore.alreadyShownSimpleInterestConversionInterstitialInThisSession) {
        logger.info(
            `showSimpleInterestConversionInterstitial = ${overviewStore.showSimpleInterestConversionInterstitial} & haven't already shown interstitial, navigating to ${RoutePaths.SIMPLE_INTEREST_CONVERSION_INTERSTITIAL}`
        )
        return await router.push(RoutePaths.SIMPLE_INTEREST_CONVERSION_INTERSTITIAL)
    }
    if (overviewStore.shouldShowPrimeV3PromptPostActivation) {
        logger.info(`shouldShowPrimeV3Prompt = true, navigating to ${RoutePaths.PRIME_V3_POST_ACTIVATION}`)
        return await router.push(RoutePaths.PRIME_V3_POST_ACTIVATION)
    }
    if (overviewStore.daysDelinquent > 0 && !overviewStore.alreadyShownDqInterstitialInThisSession) {
        logger.info(`daysDelinquent = ${overviewStore.daysDelinquent} & haven't already shown interstitial, navigating to ${RoutePaths.DQ_INTERSTITIAL}`)
        return await router.push(RoutePaths.DQ_INTERSTITIAL)
    }
    if (overviewStore.showEnrollAutoPayAndKeepAprDiscount && !overviewStore.alreadyShownEnrollInAutoPayAndKeepAprDiscount) {
        logger.info(`showEnrollAutoPayAndKeepAprDiscount = ${overviewStore.showEnrollAutoPayAndKeepAprDiscount} & haven't already shown interstitial, navigating to ${RoutePaths.SETUP_AUTOPAY}`)
        return await router.push(RoutePaths.SETUP_AUTOPAY)
    }
    if (shouldShowInstantBalanceTransferInOnboarding()) {
        await usePersonalFinancialLiabilityStore().getInstantBalanceTransferOptions()
        if (usePersonalFinancialLiabilityStore().instantBalanceTransferOptions.length > 0) {
            logger.info(`Instant BT eligible for display during onboarding, navigating to ${RoutePaths.BALANCE_TRANSFER_DURING_ONBOARDING}`)
            return await router.push(RoutePaths.BALANCE_TRANSFER_DURING_ONBOARDING)
        } else {
            logger.info(`No instant balance transfers to display in ${RouteNames.BALANCE_TRANSFER_DURING_ONBOARDING}`)
        }
    }

    logger.info(`No specific post navigation action for home product, navigating to ${globalUiStore.currentRootPathOrDefault}`)
    return await router.push(globalUiStore.currentRootPathOrDefault)
}

export const pifNativeShare = async function (config: { shareLink: string; amount: number } | { shareLink: string; subject: string; message: string }, rewardType: PifRewardType) {
    let encodedSubject: string
    let encodedMessage: string
    if ('amount' in config) {
        encodedSubject = encodeURI(
            i18n
                .t(`sharePifLink.${rewardType}.subject`, {
                    maxAmount: toFormattedUSDNoCents(config.amount),
                })
                .toString()
        )
        encodedMessage = encodeURI(
            i18n
                .t(`sharePifLink.${rewardType}.body`, {
                    maxAmount: toFormattedUSDNoCents(config.amount),
                    pifLink: config.shareLink,
                })
                .toString()
        )
    } else {
        encodedSubject = encodeURI(config.subject)
        encodedMessage = encodeURI(config.message)
    }

    logger.info(`Constructed native pif share subject='${encodedSubject}', message='${encodedMessage}' with config: ${JSON.stringify(config)}`)
    await sendMessageToNativeApp(NATIVE_MESSAGES.SHARE_PIF_LINK, `link=${config.shareLink}&message=${encodedMessage}&subject=${encodedSubject}`)
}

window.handleDeepLinkFromNative = function (deepLink: string) {
    logger.log(`handleDeepLinkFromNative - ${deepLink}`)
    parseForDeeplinkWithPathFromNative(deepLink)
}

// iOS calls this on a warm launch or while in the foreground.
const parseForDeeplinkWithPathFromNative = async (path: string) => {
    logger.info(`parseForDeeplinkWithPathFromNative, Received path: ${path}`)

    const result = getRootAndDeeplinkFromPath(path)
    if (!result) {
        logger.info('parseForDeeplinkWithPathFromNative - parsed NOTHING')
        return
    }
    logger.info(`parseForDeeplinkWithPathFromNative - parsed ${JSON.stringify(result)}`)

    const globalUiStore = useGlobalUiStore()
    globalUiStore.setCurrentRootPath(result.root)

    if (result.deeplink === '') {
        logger.info(`parseForDeeplinkWithPathFromNative - no content path, tab switch to ${JSON.stringify(result)}`)
        await router.push(useGlobalUiStore().currentRootPathOrDefault)
        return
    }
    logger.info(`parseForDeeplinkWithPathFromNative - deeplink content path ${result.deeplink}`)
    globalUiStore.updateDeeplinkPath(result.deeplink)

    if ([RoutePaths.CARD_ARRIVAL_CHECK.toString(), RoutePaths.SETUP_AUTOPAY.toString()].includes(router.currentRoute.path)) {
        return
    }
    if (!appSessionStorage.getItem(localStorageKey.jwtToken)) {
        logger.info(`parseForDeeplinkWithPathFromNative - deeplink saved and will execute in postLoginNavigation, saved deeplink path: ${result.deeplink}`)
        return
    }
    if (useOverviewStore().productType !== AvenCardProductType.NONE) {
        await router.push({
            path: globalUiStore.deeplinkPath,
            query: {
                fromDeeplink: 'true',
            },
        })
        globalUiStore.updateDeeplinkPath('')
    }
}

// native device needs to update device capabilities since user might have enrolled
// in biometrics or permitted push notifications
window.updateDeviceCapabilities = function (json: string) {
    const deviceCapabilitiesJson = JSON.parse(json)
    useDeviceInfoStore().setDeviceCapabilities(deviceCapabilitiesJson)
}

export enum NATIVE_MESSAGES {
    MOUNTED = '/mounted',
    GET_LOCATION_PERMISSIONS = '/getLocationPermissions',
    LOCATION_PERMISSIONS_SKIPPED = '/locationPermissionsSkipped',
    // Todo This was removed from the iOS app around June 14, 2024. Once all cardholders have
    //  updated to the latest version, UPDATE_TAB_BAR can be removed.
    UPDATE_TAB_BAR = '/updateTabBar',
    TAB_UPDATE = '/tabUpdate',
    BIOMETRICS = '/biometrics',
    DISABLE_BIOMETRICS = '/disableBiometrics',
    SIGNED_IN = '/signedIn',
    SHARE_PIF_LINK = '/sharePifLink',
    TOGGLE_PULL_TO_REFRESH = '/togglePullToRefresh',
    ORIGINATION_STARTED = '/originationStarted',
}

export const sendMessageToNativeApp = async (path: NATIVE_MESSAGES, queryParams?: string) => {
    const computedPath = queryParams ? `${path}?${queryParams}` : path
    logger.log(`Sending message to native app via path: ${computedPath}`)
    window.location.href = computedPath
    // A minor delay is necessary for the iOS app to catch on to the page navigation
    await new Promise((r) => setTimeout(r, 100))
}
